import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Constants } from '../../../data/constants';

const mailTo = "mailto:" + Constants.COMPANY.EMAIL;
const callTo = "tel:" + Constants.COMPANY.PHONE;

export function DefaultMessage({ AI_NAME }: { AI_NAME: string }) {
  return (
<>
<h2>Hey, welkom! 👋</h2>
    <p>Ik ben Pietertje, ontwikkeld door Pixel Ape Innovations. Ik spreek 37 talen, waaronder Nederlands, Engels, Duits, Frans en Spaans. Ik kan API's aanspreken, taken automatiseren en integreren met je bestaande software systemen. Ik leef op een server en dit is een visuele interface om met mij te communiceren. In tegenstelling tot typische chatbots, kan ik autonoom een verscheidenheid aan taken beheren, waardoor ik een waardevolle aanwinst ben voor jouw bedrijf! Vertel me meer over je bedrijf, in de zijbalk, zodat ik direct aan de slag kan als jouw assistent! Kijk ook vooral even in de suggesties.
    </p>
    
    <h3>Hoe kan ik jouw bedrijf helpen?</h3>
    
    <h4>Integratie en Automatisering</h4>
    <ul>
        <li>Naadloze integratie met bestaande software</li>
        <li>Autonoom taakbeheer</li>
    </ul>
    
    <h4>Klantenservice</h4>
    <ul>
        <li>Automatiseren van FAQ's en 24/7 ondersteuning</li>
        <li>Efficiënte klachtenafhandeling</li>
    </ul>
    
    <h4>Administratieve Taken</h4>
    <ul>
        <li>Afspraken en agendabeheer</li>
        <li>E-mailbeheer</li>
        <li>Data-invoer</li>
    </ul>
    
    <h4>Marketing en Communicatie</h4>
    <ul>
        <li>Contentcreatie: blogs, nieuwsbrieven, social media posts</li>
        <li>Social media management</li>
        <li>SEO-optimalisatie</li>
    </ul>
    
    <h4>Verkoop en Leadgeneratie</h4>
    <ul>
        <li>Leads kwalificeren</li>
        <li>Follow-up e-mails automatiseren</li>
        <li>Productinformatie verstrekken</li>
    </ul>
    
    <h4>Ondersteuning bij Besluitvorming</h4>
    <ul>
        <li>Data-analyse en rapportage</li>
        <li>Marktonderzoek</li>
        <li>Actievoorstellen doen</li>
    </ul>
    
    <h4>Interne Communicatie</h4>
    <ul>
        <li>Teamleden up-to-date houden</li>
        <li>Training en onboarding</li>
        <li>Projectmanagement ondersteuning</li>
    </ul>
    
    <h4>Financieel Beheer</h4>
    <ul>
    <li>Financiële taken stroomlijnen</li>
    <li>Facturatie</li>
    <li>Kostenbeheer en budgettering</li>
  </ul>
  <b>Klanttevredenheidsonderzoeken</b>
  <ul>
    <li>Enquêtes en feedback verzamelen</li>
  </ul>
  <p>Wil je meer weten over hoe ik jouw bedrijf kan helpen? Neem dan contact op met mijn maker via <a href={callTo}>{Constants.COMPANY.PHONE}</a> of <a href={mailTo}>{Constants.COMPANY.EMAIL}</a>.</p>
</>
  );
}
export const DefaultMessageDOM = (name:string)=> ReactDOMServer.renderToString(<DefaultMessage AI_NAME={name}/>);
