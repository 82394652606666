import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { InvoiceData } from "../../../data/models/invoice-data";
import { extraDarkColor, overlayColor, pageColor } from '../../styles/main-styles';

interface InvoiceProps {
  invoiceData: InvoiceData;
}

export function Invoice({ invoiceData }: InvoiceProps) {
  return (
    <Box sx={{ p: 4, borderRadius: '8px', backgroundColor: extraDarkColor }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid #f0f0f0', pb: 2, mb: 4 }}>
        <Box>
          <Box component={"img"} pb={2} src="pa-logo.png" height={32} width={32} alt="Pixel Ape logo"/>
          <Typography variant="h6">{invoiceData.company_name}</Typography>
          <Typography>{invoiceData.company_address}</Typography>
          <Typography>{invoiceData.company_city}, {invoiceData.company_country}</Typography>
          <Typography>{invoiceData.company_vat_number}</Typography>
        </Box>
        <Box textAlign="right">
          <Box height={32} width={32} pb={2} />
          <Typography variant="h6">{invoiceData.customer_name}</Typography>
          <Typography>{invoiceData.customer_address}</Typography>
          <Typography>{invoiceData.customer_postal_code} {invoiceData.customer_city}, {invoiceData.customer_country}</Typography>
          <Typography>{invoiceData.customer_vat_number}</Typography>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{backgroundColor:pageColor}}>
            <TableRow>
              <TableCell>Product Beschrijving</TableCell>
              <TableCell>Aantal</TableCell>
              <TableCell>Prijs</TableCell>
              <TableCell>Totaal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor:overlayColor}}>
            {invoiceData.product_lines.map((line, index) => (
              <TableRow key={index}>
                <TableCell>{line.product_description}</TableCell>
                <TableCell>{line.product_quantity}</TableCell>
                <TableCell>€{line.product_price}</TableCell>
                <TableCell>€{line.product_total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box textAlign="right" mt={4}>
        <Typography>Subtotaal: €{invoiceData.subtotal}</Typography>
        <Typography>BTW {invoiceData.vat_percentage}%: €{invoiceData.vat_amount}</Typography>
        <Typography variant="h6">Totaal: €{invoiceData.total_amount}</Typography>
      </Box>
      <Box borderTop="2px solid #f0f0f0" pt={2} mt={4} textAlign="center" color="#888">
        <Typography>Betalingstermijn: {invoiceData.payment_term}</Typography>
        <Typography>Bankrekeningnummer: {invoiceData.bank_account_number}</Typography>
        <Typography>Referentie: {invoiceData.payment_reference}</Typography>
        <Typography>Opmerking: {invoiceData.notes}</Typography>
        <br></br>
        <Typography>{invoiceData.terms_and_conditions}</Typography>
        <Typography></Typography>
      </Box>
    </Box>
  );
}
