import { MessageModel } from "@chatscope/chat-ui-kit-react";
import { GPTMessage, Role, RoleType } from "../../data/models/gpt-message";
import { Constants } from "../../data/constants";
import { Tool } from "../../data/models/tool";
import { File } from "../../data/models/file";
import { ImageMessageDOM } from "../../view/components/templates/image-message";
import { MessageBodyDOM } from "../../view/components/chat/message-body";

export interface MessageServiceListener {
    onMessagesUpdate: (messages:Array<MessageModel>)=>void;
}

class MessageService { //TODO move to store. TODO make more generic
    
    private messages: Array<GPTMessage> = [];
    private listeners: Array<MessageServiceListener> = [];
    private temperature: number = 1;
    private tools: Array<Tool> = [];

    private model: string = Constants.DEFAULT_AI_SETTINGS.model;

    public setup(message: string, temperature: number = 1, tools: Array<Tool> = [], model: string = Constants.DEFAULT_AI_SETTINGS.model){
        this.clear();

        this.model = model;
        this.tools = tools;
        this.setTemperature(temperature);
        this.addSystemMessage(message);
        this.callListeners();
    }

    public setupStacked(messages: Array<string>, temperature: number = 1, tools: Array<Tool> = [], model: string = Constants.DEFAULT_AI_SETTINGS.model){
        this.clear();

        this.model = model;
        this.tools = tools;
        this.setTemperature(temperature);
        messages.forEach(message=>this.addSystemMessage(message));
     
        this.callListeners();
    }

    public setTemperature(temperature: number){
        this.temperature = +temperature;
    }

    public addAIMessage(prompt: string){
        this.addMessage(Role.AI,prompt);
    }

    public addSystemMessage(prompt: string){
        this.addMessage(Role.SYSTEM,prompt)
    }

    public addUserMessage(prompt: string, file: File = File.empty()){
        this.addMessage(Role.USER,prompt, file);
    }

    private addMessage(role: RoleType, prompt: string, file: File = File.empty()){
        this.messages.push(new GPTMessage(role, prompt, file.data))
    }

    public getMessages(): Array<GPTMessage>{
        return this.messages;
    }

    public getMessagesAsChatMessages(): Array<MessageModel>{ //TODO LOS MAKEN VAN UI LOGICA
        const senderId = (role: string)=>{
            if(role===Role.USER) return 0;
            if(role===Role.AI) return 1;
            if(role===Role.SYSTEM) return 2;
        }
        return this.messages.map((message:GPTMessage)=>{
            const outgoing = message.role === Role.USER;
            return {
                position:"normal",
                direction: outgoing? "outgoing" : "incoming",
                message: message.base64Image.length>0? ImageMessageDOM(MessageBodyDOM(message.prompt), message.base64Image) : MessageBodyDOM(message.prompt), //TODO change to content once we find out how to handle images in ui
                sentTime: "just now",
                sender: senderId(message.role)?.toString(),
            } as MessageModel
        });
    }

    public async queryAI(message: string, file: File = File.empty(), add = true){ 

        console.log("asking GPT: "+message);
        try {
            if(add)this.addUserMessage(message, file);

            const response = await this.GTPPost(message, file);
            const GPTResponse = await response.json();

            if(GPTResponse.tools.length>0){
                this.handleTools(GPTResponse.tools);
            } else {
                this.addAIMessage(GPTResponse.content);
            }
            this.callListeners();
        } catch(e){
            //TODO do we rollback?
            console.log(e);
        }
    }

    private handleTools(tools: any){
        tools.forEach((tool: any)=>{
            this.tools.forEach(t=>{
                if(t.identifier === tool.function.name){
                    const obj  = JSON.parse(tool.function.arguments);
                    this.addAIMessage(obj.reply);
                    t.executionCallback(obj);
                }
            })
        })
    }


    public async GTPPost(message: string, file: File = File.empty(), role: RoleType = Role.USER){ //TODO add error handling and URL 
        const body = {
            prompt: message,
            role: role,
            context: this.messages,
            temperature: this.temperature,
            model: this.model
        } as any

        if(this.tools.length>0) body.tools = this.tools.map(tool=>tool.toolFunction);
        if(File.hasValue(file)) body.base64Image = file.data; 

        return await fetch(`${process.env.REACT_APP_BACKEND_URL}`,{
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(body)
        }); 

    }

    public replace(index: number, message: GPTMessage){
        this.messages[index] = message;
    }

    private clear(){
        this.messages = [];
    }

    public addListener(listener: MessageServiceListener): number{
        return this.listeners.push(listener) - 1;
    }

    public removeListener(index: number): void {
        this.listeners.splice(index,1);
    }

    public callListeners(){
        console.log(this.messages);
        this.listeners.forEach(listener=>listener.onMessagesUpdate(this.getMessagesAsChatMessages()));
    }
    
}
export const MessageServiceInstance = new MessageService();