import { Component } from "react";
import { theme } from "../styles/main-styles";
import { Box, Typography, } from "@mui/material";
import Spreadsheet from "react-spreadsheet";
import { SettingsToolbar } from "../components/settings-toolbar";
import { AssistantDataStore } from "../../data/data-store";
import { BaseChat } from "./base-chat";
import { SettingsNotice } from "../components/common/settings-notice";
import { Tool } from "../../data/models/tool";
import { MessageServiceInstance } from "../../domain/services/message.service";
import { GPTMessage, Role } from "../../data/models/gpt-message";

interface Cell {
    value: string;
}

interface State {
    labels: string[];
    data: any
    systemMessage: string;
}

export class Analyst extends Component<{}, State> { //Give GPT a list of  updated data
    public state = {
        labels: ["Naam", "Email", "Product", "Prijs", "Review"],
        data: [
            [{ value: "Jan Jansen" }, { value: "jan@example.com" }, { value: "Koekjes" }, { value: 7.30 }, { value: "Gemiddeld" }],
            [{ value: "Anna de Vries" }, { value: "anna@example.com" }, { value: "Aardbei" }, { value: 7.15 }, { value: "Goed" }],
            [{ value: "Pieter Bakker" }, { value: "pieter@example.com" }, { value: "Chocolade" }, { value: 3.41 }, { value: "Goed" }],
            [{ value: "Sophie Visser" }, { value: "sophie@example.com" }, { value: "Koekjes" }, { value: 9.35 }, { value: "Uitstekend" }],
            [{ value: "Emma Smit" }, { value: "emma@example.com" }, { value: "Chocolade" }, { value: 5.11 }, { value: "Goed" }],
            [{ value: "Lucas de Boer" }, { value: "lucas@example.com" }, { value: "Chocolade" }, { value: 1.38 }, { value: "Gemiddeld" }],
            [{ value: "Sara Mulder" }, { value: "sara@example.com" }, { value: "Koekjes" }, { value: 3.22 }, { value: "Slecht" }],
            [{ value: "Liam de Groot" }, { value: "liam@example.com" }, { value: "Vanille" }, { value: 6.88 }, { value: "Gemiddeld" }],
            [{ value: "Eva de Bruin" }, { value: "eva@example.com" }, { value: "Vanille" }, { value: 2.32 }, { value: "Gemiddeld" }],
            [{ value: "Noah van Dijk" }, { value: "noah@example.com" }, { value: "Aardbei" }, { value: 4.18 }, { value: "Gemiddeld" }],
        ],
        systemMessage: ""
    }

    public render(){
        return (
            <BaseChat 
                store={AssistantDataStore} 
                chatTitle={"AI Data Analist Demo"} 
                chatTitleMobile={"Data Analist Demo"} 
                defaultMessageContent={this.defaultMessage()}
                actionContainer={this.renderSheetContainer()}
                inputPlaceholder="Wat wil je doen of weten?"
                temperature={0}
                drawerOpenOnStart={window.innerWidth < theme.breakpoints.values.xl}
                systemMessages={[this.state.systemMessage]}
                tools={[
                    new Tool("change_data", this.tool() ,args=>{
                        const data = JSON.parse(args.data);
                        console.dir(data);
                        MessageServiceInstance.addSystemMessage(this.dataUpdatedSystemMessage(args.data)); //somewhat of a hack to bypass re-rendering
                        this.setState({data: data});
                    }),
                ]}
            />
        )
    }

    public componentDidMount(): void {
        this.setState({systemMessage: this.buildSystemMessage()});
    }

    public renderSheetContainer(){
        const sheetMargin = { xs:0, md:4 };
        const wh = window.innerHeight
        return (
            <Box>
                <SettingsToolbar/>
                <Box style={{boxSizing:"border-box", maxWidth:600}}>
                    <Typography variant="h5"  sx={{ flexGrow: 1, ml:4, mt:2,mb:2}} >Data Bron</Typography>
                    <Typography variant="body1" sx={{ flexGrow: 1, ml:4, mb:4, pr:{xs:2, md:16}}} >Pas data aan door de cel aan te klikken. Je kan natuurlijk ook de AI vragen om dit voor je te doen!</Typography>
                </Box>
                <Box sx={{
                    position: 'relative',
                    backgroundColor: "extraDarkColor",
                    ml:sheetMargin,
                    mr:sheetMargin,
                    boxSizing: 'border-box',
                    height:"auto",
                    maxHeight:{
                        xs: wh-(wh*0.4),
                        md: wh-(wh*0.2),
                    },
                    maxWidth: {
                        xs: 'inherit',
                        md: 'inherit',
                        // xl: '600px',
                        // xxl: '800px'
                    },
                    overflow: 'auto',
                    '& .ReactVirtualized__Table': {
                        width: 'max-content',
                        minWidth: '100%'
                    }
                }}>
                    <Spreadsheet 
                        className="data-table"
                        onChange={(data)=>this.setState({data})}
                        data={this.state.data}
                        columnLabels={this.state.labels}  
                        darkMode={true}
                    />
                </Box>
                <Box sx={{ display:"flex", flexDirection:"column", alignItems:"start", ml:4}}>
                    <SettingsNotice/>
                </Box>
            </Box>
        );
    }

    public defaultMessage(){
        return `Hallo, ik ben een data-analist en ik geef gedetailleerde informatie over de data die aan mij wordt aangeleverd. Momenteel ben ik gekoppeld aan de tabel in de zijbalk. Ik kan de data ook aanpassen als je dat aan mij vraagt.`
    }

    public buildSystemMessage(){
        return `
        Je bent een analist en geeft gedetailleerde informatie over spreadsheets en datasets. De dataset is in CSV of JSON formaat.
        Als er wordt gevraagd om informatie uit de tabel, geef dit dan in natuurlijke taal weer. Wees zo precies mogelijk.
        Als er om veranderingen wordt gevraagd wijzig dan niet de property namen in de originele JSON of CSV maar enkel de waarden.
        Je hebt de volgende functies beschikbaar:
            - change_data: Gebruik deze functie alleen als de gebruiker verzoekt dat je bepaalde data aanpast. Gebruik deze functie niet bij analytische,inzichtelijke en rekenvragen zoals: optellen, aftrekken, delen en vermenigvuldigen van bepaalde waarden.
        De dataset is als volgt: ${this.dataAsJSON()}`.trim()
    };

    public dataUpdatedSystemMessage(source: any){
        return `De data is nu: column labels: ${this.state.labels} data: ${source}`.trim();
    }

    public dataAsJSON(): string {
        return `column labels: ${JSON.stringify(this.state.labels)} data: ${JSON.stringify(this.state.data)} `;
    }

    private tool(){
        return {
            type: "function",
            function: {
                name: "change_data",
                description: "Changes the data as the user requests. Always returns the complete and updated dataset as JSON in the format Array<Array<{value:string}>>",   
                parameters: {
                    type: "object",
                    properties: {
                        data: {
                            type: "string",
                            description: "The complete updated dataset as JSON in the format Array<Array<{value:string}>>"
                        },
                        reply: {
                            "type": "string",
                            "description": "What you want to say to the user",
                        },
                        type_of_change:{
                            type: "string",
                            description: "type of change done. chose between te following: create, update, delete"
                        }
                    },
                    required: ["data","reply","type_of_change"]
                }
            }
        }
    }
}