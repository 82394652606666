import { Button } from "@mui/material";

interface AppButtonProps {
    title: string;
    onClick?: () => void;
    style?: any;
    disabled?: boolean;
    type?: "submit" | "button";
}

const buttonStyle = {
    boxShadow: 0,
    marginTop:2, 
    color:"black", 
    borderRadius:12, 
    textTransform: "none",
    width:200, mt:4
}

export function AppButton({ title, onClick, style, disabled = false, type="button"}: AppButtonProps ) {
  return (
    <Button 
        type={type}
        disabled={disabled}
        sx={{...buttonStyle,...style, }} size="large" disableElevation variant="contained"
        onClick={()=>onClick?.()}>{title}</Button>
  );
}