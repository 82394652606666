import { createTheme } from "@mui/material";

export const mode = "dark";
export const isDarkMode = (mode === "dark");

export const accentColor = "#58c4dc";
export const pageColor = isDarkMode? "#23272f" : "white";
export const containerColor = isDarkMode? "#1c1f25" : "white";
export const overlayColor = isDarkMode? "#333a45" : "black";
export const extraDarkColor = isDarkMode? "#16181d" : "white";
export const textColor = isDarkMode? "white" : "black"; 
export const subTextcolor = isDarkMode? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)";
export const shadowColor = isDarkMode? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.05)";


const inputBoxHeight = "64px";
const inputBoxHeightMobile = "48px";
const padding = 32;
const fullSpace = {
    default: {
        width:"100%", height:"100%"
    },
    absolute: {
        position:"absolute", width:"100%", height:"100%"
    },
    relative: {
        position:"relative", width:"100%", height:"100%"
    }
} as const

export const styles = {
    ////////////////////////////////////////////////////////////////////////////// TODO clean

    mainPage: {
        pageContainer:{
            display:"flex",
            flexDirection: "column",
            ...fullSpace.absolute,
        },
        contentContainer: {
            flexGrow:1,
            display:"flex",
            flexDirection: "row",
            alignItems:"stretch",
            backgroundColor: pageColor,
        },
        leftContainer:{
            container: {
                flexGrow:1,
                //maxWidth:"400px",
                display:"flex",
                flexDirection:"column",
                borderRight: `1px solid ${shadowColor}`,
                backgroundColor: containerColor
            },
            contentWrapper:{
                paddingLeft:padding,
                paddingRight:padding,
            },
            bottomWrapper:{
                marginTop:32,
                display:"flex",
                flexDirection:"column",
                alignItems: "center"
            },
            textField:{
                height:128,
                width:"100%",
            },
            saveButton: {
                boxShadow: 0,
                marginTop:2, 
                width:"100%", 
                color:"black", 
                borderRadius:12, 
                textTransform: "none"
            },
            switchContainer: {
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
            },
        },

        rightContainer:{
            container: {
                flexGrow:19,
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
            },
            chatContainer:{
                flexGrow: 1,
                height:1,
                boxSizing: "border-box",
                width:"100%",
            }
        },

        drawer:{
            width: {
                xs:"80%",
                md:"60%",
                xl:"40%"
            },
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
                width: {
                    xs:"80%",
                    md:"60%",
                    xl:"40%"
                },
                boxSizing: 'border-box',
            }
        },

        snackbar:{
            sx:{
                backgroundColor:extraDarkColor,
                color:"white",
                borderRadius:12,
                paddingLeft:4
            }
        }
    },


    
    analystPage: {
        pageContainer:{
            display:"flex",
            flexDirection: "column",
            ...fullSpace.absolute,
        },
        contentContainer: {
            flexGrow:1,
            display:"flex",
            flexDirection: "row",
            alignItems:"stretch",
            backgroundColor: pageColor,
        },
        leftContainer:{
            container: {
                flexGrow:1,
                //maxWidth:"400px",
                display:"flex",
                flexDirection:"column",
                borderRight: `1px solid ${shadowColor}`,
                backgroundColor: containerColor
            },
            contentWrapper:{
                paddingLeft:padding,
                paddingRight:padding,
            },
            bottomWrapper:{
                marginTop:32,
                display:"flex",
                flexDirection:"column",
                alignItems: "center"
            },
            textField:{
                height:128,
                width:"100%",
            },
            saveButton: {
                boxShadow: 0,
                marginTop:2, 
                width:"100%", 
                color:"black", 
                borderRadius:12, 
                textTransform: "none"
            },
            switchContainer: {
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
            },
        },

        rightContainer:{
            container: {
                flexGrow:19,
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
            },
            chatContainer:{
                flexGrow: 1,
                height:1,
                boxSizing: "border-box",
                width:"100%",
            }
        },

        drawer:{
            width: {
                xs:"90%",
                md:400,
            },
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
                width: {
                    xs:"90%",
                    md:400,
                },
                boxSizing: 'border-box',
            }
        },

        snackbar:{
            sx:{
                backgroundColor:extraDarkColor,
                color:"white",
                borderRadius:12,
                paddingLeft:4
            }
        }
    },




    bottomBar:{
        inputContainer: {
            mt:{xl:4, xs:1}, //bug?
            marginTop:{xl:4, xs:1}, //bug?
            paddingRight:{
                xs:0,
                md:2,
            },
            marginRight:{
                xs:4,
                md:0,
            },
            marginLeft:{
                xs:4,
                md:0,
            },
            height: {
                xs:inputBoxHeightMobile,
                md:inputBoxHeight,
            },
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            width: {
                xs:"90%",
                md:"800px",
            },
            boxSizing: "border-box",
            overflow: "hidden",
            borderRadius: 200,
            backgroundColor: "#333a45",
        },
        inputBar: {
            overflow: "hidden",
            height: {
                xs:inputBoxHeightMobile,
                md:inputBoxHeight,
            },
            flexGrow: 1, // Changed from width: "100%" to flexGrow
            display: "flex",
            alignItems: "center",
        },
        chatBox: {
            width: "100%",
            paddingLeft: padding,
        },
        sendButton: {
            backgroundColor: "transparent",
            marginRight: 0,
        },
        disclaimer:{
            
        }
    },



    subTitle: { //TODO clean
        color: subTextcolor,
        fontWeight:"normal",
    },


    serverStatus:{
        container:{
            backgroundColor: "#dc5861",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
        },
        text:{
            color:"white",
            fontSize: {
                xs:12,
                md:16
            },
            pb:1,
            pt:1
        }
    },

    landingsToolbar: {
        toolbar: {
            position: "relative",
            boxSizing: "border-box",
            marginTop:8,
            width: "100%", background:"rgba(35,39,47,0.5)",
            //backgroundColor: pageColor,
            color: textColor,
           // borderBottom: `2px solid ${shadowColor}`,
        },
        image: {
            height: { xs:42, md:42 },
            width: { xs:42, md:42 },
            marginRight: 2,
            cursor:"pointer"
        },
    },
    settingsToolbar: {
        toolbar: {
            position: "relative",
            boxSizing: "border-box",
            width: "100%",
            backgroundColor: containerColor,
            color: textColor,
            borderBottom: `2px solid ${shadowColor}`,
        },
        image: {
            height: 42,
            width: 42,
            marginRight: 16,
            cursor:"pointer"
        },
    },
    chatToolbar: {
        toolbar: {
            position:"relative",
            boxSizing:"border-box",
            width:"100%",
            backgroundColor: pageColor, 
            color:textColor, 
            borderBottom:`2px solid ${shadowColor}`
        },
        modalSX: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs:"80%", md: 400 },
            backgroundColor: containerColor,
            borderRadius: "2px",
            boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.2);",
            pt: 4,
            px: 4,
            pb: 3,
        }
    },
    effects: {
        blur: {
            backdropFilter: "blur(10px)",
        }
    }
} as const

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: accentColor,
        },
        secondary: {
            main: accentColor,
        },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1920,
          xxxl: 2560,
        },
      },
});

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      xs: true; // removes the `xs` breakpoint
      sm: true;
      md: true;
      lg: true;
      xl: true;
      xxl: true;
      xxxl: true;
    }
  }