import Markdown from 'react-markdown'
import { Utils } from '../../../utils/utils';
import ReactDOMServer from 'react-dom/server';
import remarkGfm from 'remark-gfm'

export function MessageBody({text }: { text: string}) {
  return (
        <>
            { Utils.isMarkdown(text)? <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown> : <>{text}</> }
        </>
  );
}

export const MessageBodyDOM = (text: string )=> ReactDOMServer.renderToString(<MessageBody text={text}/>);