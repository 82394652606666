import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PublicIcon from '@mui/icons-material/Public';
import { Constants } from '../../data/constants';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Slide, Typography, Zoom } from '@mui/material';
import { styles } from '../styles/main-styles';
import React from 'react';

export function ContactDialog({open, onClose}:{open:boolean, onClose:()=>void}){
    return (   
        <Modal open={open} onClose={()=>onClose()} >
            {/* <Zoom in={open}>  */}
                <Box sx={styles.chatToolbar.modalSX}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">Contact</Typography>
                    <List>                          
                        {renderListItem(<AlternateEmailIcon/>, Constants.COMPANY.EMAIL, ()=>openMailClient())}
                        {renderListItem(<LocalPhoneIcon/>, Constants.COMPANY.PHONE, ()=>callPhone())}
                        {renderListItem(<PublicIcon/>, Constants.COMPANY.WEBSITE, ()=>openWebsite())}
                    </List>
                </Box>
            {/* </Zoom>    */}
        </Modal>
    );
}

function renderListItem(icon: any, text: string, onClick: () => void){
    return (<ListItem disablePadding>
        <ListItemButton onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
    </ListItem>);
}

function openMailClient(){
    window.location.href = `mailto:${Constants.COMPANY.EMAIL}?subject=AI Integratie&body=`;
}

function callPhone(){
    window.location.href = `tel:${Constants.COMPANY.PHONE}`;
}

function openWebsite(){
    window.location.href = Constants.COMPANY.WEBSITE;
}
