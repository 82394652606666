import { Constants } from "../data/constants";

export class Utils {

    static isMarkdown(text: string) {
        const markdownPatterns = [
            /^#{1,6}\s/gm,                // Headers
            /^[-+*]\s/gm,                 // Unordered lists
            /^\d+\.\s/gm,                 // Ordered lists
            /\*\*(.*?)\*\*/g,             // Bold
            /__(.*?)__/g,                 // Bold
            /\*(.*?)\*/g,                 // Italics
            /_(.*?)_/g,                   // Italics
            /\[(.*?)\]\((.*?)\)/g,        // Links
            /!\[(.*?)\]\((.*?)\)/g,       // Images
            /```([\s\S]*?)```/g           // Code blocks
        ];
        return markdownPatterns.some(pattern => pattern.test(text));
    }

    static makeTextPresentable(text: string){
        if(Utils.isMarkdown(text)){
            return "";
        }
        return text;
    }


    static isNotLocalhost(urlString: string): boolean {
        try {
          const url = new URL(urlString);
          const hostname = url.hostname;
          return hostname !== 'localhost' && hostname !== '127.0.0.1';
        } catch (e) {
          console.error('Invalid URL', e);
          return false;
        }
    }      

    static openWhatsapp(){
        const phone = Constants.COMPANY.PHONE;
        const iPhone = phone.replace(/^\+31\s*/, '');
        const message = "Hallo, ik heb een vraag over jullie diensten."; // Optioneel, een vooraf ingevulde boodschap
        
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent) || /Mac/i.test(navigator.userAgent)) {
            window.open(`https://wa.ms/${iPhone}?text=${encodeURIComponent(message)}`);
            return;
        } 
        window.location.href = `whatsapp://send?phone=${Constants.COMPANY.PHONE}?text=${encodeURIComponent(message)}`;
    }

    static openMailClient(){
        window.location.href = `mailto:${Constants.COMPANY.EMAIL}?subject=AI Integratie&body=`;
    }
    
    static callPhone(){
        window.location.href = `tel:${Constants.COMPANY.PHONE}`;
    }
    
    static openWebsite(){
        window.location.href = Constants.COMPANY.WEBSITE;
    }
}