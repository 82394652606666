export class File {
    name: string;
    data: string;
    
    public constructor(name: string, data: string) {
        this.name = name;
        this.data = data;
    }

    public static hasValue(file: File): boolean {
        if(file === null) return false;
        return file.name.length>0 && file.data.length>0;
    }

    static empty(): File {
        return new File("","");
    }
}