import { Component, ComponentType, useEffect, useState } from "react";
import { create, StoreApi } from "zustand";
import { Constants, DEFAULT_ASSISTANT_SETTINGS, MODELS } from "./constants";

const STORAGE_KEY_COMPANY_DESCRIPTION = "company_description";
const STORAGE_KEY_PERSONALITY = "personality";
const STORAGE_KEY_DESCRIPTION = "description"; 
const STORAGE_KEY_STRICT_MODE = "strict_mode";
const STORAGE_KEY_FIRST_TIME = "first_time";
const STORAGE_KEY_TEMPERATURE = "temperature";
const STORAGE_KEY_MODEL = "model";

const STORAGE_KEY_COMPANY_NAME = "company_name";
const STORAGE_KEY_COMPANY_ADDRESS = "company_address";
const STORAGE_KEY_COMPANY_POSTAL_CODE = "company_postal_code";
const STORAGE_KEY_COMPANY_CITY = "company_city";
const STORAGE_KEY_COMPANY_COUNTRY = "company_country";
const STORAGE_KEY_COMPANY_VAT_NUMBER = "company_vat_number";


const stringToBool = (str: string) => {

    if (typeof str == "boolean") return str;
    if(str.toLowerCase() === 'true') return true;
    return false;
}
const loadPref = (key: string, defaultValue: any) => {
    const value = localStorage.getItem(key);
    const settingExist = value !== null;
    return settingExist ? value : defaultValue;
}

interface GlobalDataStoreType {
    serverRunning: boolean;
    setServerRunning: (value: boolean) => void;
}

export const GlobalDataStore: StoreApi<GlobalDataStoreType> = create(set=>({
    serverRunning: true,

    setServerRunning: (value: boolean) => set(()=>({serverRunning: value})),
}));


interface AssistantDataStoreType {
    companyDescriptionSetting: string;
    descriptionSetting: string;
    personalitySetting: string;
    strictMode: boolean;
    firstTime: boolean;
    temperature: number;
    model: string;

    setCompanyDescriptionSetting: (value: string) => void;
    setDescriptionSetting: (value: string) => void;
    setPersonalitySetting: (value: string) => void;
    setStrictMode: (value: boolean) => void;
    setFirstTime: (value: boolean) => void;
    setTemperature: (value: number) => void;
    setModel: (value: string) => void;
}

export const AssistantDataStore: StoreApi<AssistantDataStoreType> = create(set=>({
    companyDescriptionSetting: loadPref(STORAGE_KEY_COMPANY_DESCRIPTION, DEFAULT_ASSISTANT_SETTINGS[1].company),
    descriptionSetting: loadPref(STORAGE_KEY_DESCRIPTION, DEFAULT_ASSISTANT_SETTINGS[1].description),
    personalitySetting: loadPref(STORAGE_KEY_PERSONALITY, DEFAULT_ASSISTANT_SETTINGS[1].personality),
    strictMode: stringToBool(loadPref(STORAGE_KEY_STRICT_MODE, false)),
    temperature: loadPref(STORAGE_KEY_TEMPERATURE, 0.5),
    model: loadPref(STORAGE_KEY_MODEL, Constants.DEFAULT_AI_SETTINGS.model),
    firstTime: stringToBool(loadPref(STORAGE_KEY_FIRST_TIME, true)),

    setCompanyDescriptionSetting:(value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_DESCRIPTION, value);
        return {companyDescriptionSetting: value}
    }),

    setDescriptionSetting: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_DESCRIPTION, value);
        return {descriptionSetting: value}
    }),

    setPersonalitySetting: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_PERSONALITY, value);
        return {personalitySetting: value}
    }),

    setStrictMode: (value: boolean) => set(()=>{
        localStorage.setItem(STORAGE_KEY_STRICT_MODE, value.toString());
        return {strictMode: value};
    }),

    setTemperature: (value: number) => set(()=>{
        localStorage.setItem(STORAGE_KEY_TEMPERATURE, value.toString());
        return {temperature: value};
    }),

    setFirstTime: (value: boolean) => set(()=>{
        localStorage.setItem(STORAGE_KEY_FIRST_TIME, value.toString());
        return {firstTime: value};
    }),

    setModel: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_MODEL, value);
        return {model: value}
    }),
}));


interface CompanyDataStoreType {
    companyName: string;
    companyAddress: string;
    companyPostalCode: string;
    companyCity: string;
    companyCountry: string;
    companyVatNumber: string;

    setCompanyName: (value: string) => void;
    setCompanyAddress: (value: string) => void;
    setCompanyPostalCode: (value: string) => void;
    setCompanyCity: (value: string) => void;
    setCompanyCountry: (value: string) => void;
    setCompanyVatNumber: (value: string) => void;
}

export const CompanyDataStore: StoreApi<CompanyDataStoreType> = create(set=>({

    companyName: loadPref(STORAGE_KEY_COMPANY_NAME, ""),
    companyAddress: loadPref(STORAGE_KEY_COMPANY_ADDRESS, ""),
    companyPostalCode: loadPref(STORAGE_KEY_COMPANY_POSTAL_CODE, ""),
    companyCity: loadPref(STORAGE_KEY_COMPANY_CITY, ""),
    companyCountry: loadPref(STORAGE_KEY_COMPANY_COUNTRY, ""),
    companyVatNumber: loadPref(STORAGE_KEY_COMPANY_VAT_NUMBER, ""),

    setCompanyName: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_NAME, value.toString());
        return {companyName: value};
    }),
    setCompanyAddress: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_ADDRESS, value.toString());
        return {companyAddress: value};
    }),
    setCompanyPostalCode: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_POSTAL_CODE, value.toString());
        return {companyPostalCode: value};
    }),
    setCompanyCity: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_CITY, value.toString());
        return {companyCity: value};
    }),
    setCompanyCountry: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_COUNTRY, value.toString());
        return {companyCountry: value};
    }),
    setCompanyVatNumber: (value: string) => set(()=>{
        localStorage.setItem(STORAGE_KEY_COMPANY_VAT_NUMBER, value.toString());
        return {companyVatNumber: value};
    }),
}));





//TODO change to multiple stores HOC 
export function storeSubscription<T extends object>( 
    Comp: ComponentType<T>, 
    store: StoreApi<any>
){
    return function (props: T){ //T?
        const [, forceUpdate] = useState(0); 

        useEffect(()=>{
            const unsubscribe = store.subscribe(() => {
                forceUpdate(n=>n+1); 
            });

            return () => {
                unsubscribe();
            };
        }); 
        return <Comp {...props} />
    }
}

export function storeSubscriptions<T extends object>(
    Comp: ComponentType<T>,
    stores: StoreApi<any>[]
) {
    return function (props: T) {
        const [, forceUpdate] = useState(0);

        useEffect(() => {
            const unsubscribes = stores.map(store =>
                store.subscribe(() => {
                    forceUpdate(n => n + 1);
                })
            );

            return () => {
                unsubscribes.forEach(unsubscribe => unsubscribe());
            };
        }, []); //TODO [stores] ?

        return <Comp {...props} />;
    }
}