import { Typography } from "@mui/material";
import { ReactNode } from "react";

type ComponentType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button";
interface TitleProps {
    children: ReactNode;
    sx?: any;
    mobileComponent?: ComponentType;
    desktopComponent?: ComponentType;
  }
export function Title({children, sx, mobileComponent, desktopComponent} : TitleProps){
    return (
        <>
            <Typography variant={desktopComponent} component="div" sx={{...sx, display:{xs:"none", md:"inherit"}}}>{children}</Typography>
            <Typography variant={mobileComponent} component="div" sx={{...sx, display:{xs:"inherit", md:"none"}}}>{children}</Typography>
        </>
    )
}