import { Box, Typography } from '@mui/material';
import { LandingToolbar } from '../components/landing-toolbar';

export function Error(){
    return (
        <Box>
            <LandingToolbar large={true} />
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:"100vh", width:"100vw" }}>
                
                <Typography variant="h2">404</Typography>
                <Typography variant="h5">Oeps, er lijkt iets mis te zijn gegaan.</Typography>
            </Box>
        </Box>
    )
}