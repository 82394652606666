export class Tool { 

    public identifier: string;
    public toolFunction: object;
    public executionCallback: (args:any)=>void;

    constructor(identifier: string, toolFunction: object, executionCallback: (args:any)=>void){
        this.identifier = identifier;
        this.toolFunction = toolFunction;
        this.executionCallback = executionCallback;
    }
}