import { Component } from "react";
import HelpIcon from '@mui/icons-material/Help';
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { styles } from "../../styles/main-styles";

interface Props{
    children: any;
    title: string;
}
interface State{
    showContactModal: boolean;
}

export class HelpMenu extends Component<Props, State> {

    state = { 
        showContactModal: false
    }
    

    public render() {
      return (
        <>
            <IconButton
            disableRipple={true}
            size="large"
            onClick={() => this.setState({showContactModal:true})}
            color="inherit"
            aria-label="menu">
                <HelpIcon/>
            </IconButton>
            <Modal open={this.state.showContactModal} onClose={()=>this.setState({showContactModal: false})} >
                <Box sx={styles.chatToolbar.modalSX}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">{this.props.title}</Typography>
                    {this.props.children}
                </Box>
            </Modal>
        </>
    );
    }
}