import UniversalRouter from 'universal-router';
import { Main } from './pages/main';
import { Landing } from './pages/landing';
import { createBrowserHistory } from 'history';
import { Analyst } from './pages/analyst';
import { InvoiceBuilderAssistant } from './pages/invoice-builder';
import { VisionMain } from './pages/vision-main';
import { CarInfo } from './pages/carinfo';
import { DocumentReader } from './pages/document-reader';

// Route-definities
const routes = [
  {
    path: '/',
    action: () => <Landing />
  },
  {
    path: '/assistant',
    action: () => <Main />
  },
  {
    path: '/analyst',
    action: () => <Analyst />
  },
  {
    path: '/invoice-builder',
    action: () => <InvoiceBuilderAssistant />
  },{
    path: "/vision-assistant",
    action: () => <VisionMain />
  },
  { path: '/carinfo', action: () => <CarInfo /> },
  { path: '/document-reader', action: () => <DocumentReader /> },
  { path: '*', action: () => <Landing /> }
];
export const router = new UniversalRouter(routes);
export const history = createBrowserHistory();
export function navigate(path: string): void {
  history.push(path);

    // Scroll to the section if it's an anchor link
    if (path.startsWith('#')) {
      const element = document.querySelector(path);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
}
