import { File } from "./file";

export type RoleType = "system" | "user" | "assistant";
export class Role {
    static readonly SYSTEM: RoleType = "system";
    static readonly AI: RoleType = "assistant";
    static readonly USER: RoleType = "user";
}

export class GPTMessage {

    public role: RoleType;
    public content: string | Array<any>;
    public base64Image: string;

    public prompt: string;

    constructor(role: RoleType, content: string, file: string = ""){
        this.prompt = content
        this.base64Image = file;

        this.role = role;
        this.content = this.buildContent();
    }

    private buildContent(): string | Array<any> { //TODO model
        if(this.base64Image.length>0) {
            return [
                { type: "text", text: this.prompt },
                {
                    type: "image_url",
                    image_url: {
                        url: this.base64Image,
                    }
                }
            ]
        } else return this.prompt;
    }
}