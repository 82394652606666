import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, Paper, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { AppButton } from '../common/app-button';
import { accentColor } from '../../styles/main-styles';

const ContactForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const clear = ()=>{
    setName('');
    setEmail('');
    setMessage('');
    setLoading(false);
  }

  const sendError = ()=>{
    setError(true);
    setSent(false);
    clear();
  }
  const success = ()=>{
    setSent(true);
    setError(false);
    clear();
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try  {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/webmail`,{
          method: "POST",
          headers: {
              "Content-Type" : "application/json",
          },
          body: JSON.stringify({
            name, email, message
          })
      }); 
      const serverData = await response.json()
      if(serverData.success){
        success();
      } else {
        sendError();
      }
    } catch (error) {
      console.log(error);
      sendError();
    }
  };

  return (
    <Container component="main">
      <Box>
        <Typography variant="h6" component="div" align="center" gutterBottom>
          Stuur ons een mailtje
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name"
                label="Naam"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="message"
                label="Bericht"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          {loading ? (
            <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <AppButton title="Verstuur" type="submit" style={{ mt: 4, mb: 0, width: '100%' }} />
          )}
          {error && <Typography variant="body1" sx={{ color: '#f94449', mt: 2 }}>Er is iets misgegaan. Neem alstublieft via een ander kanaal contact met ons op.</Typography>}
          {sent && !error && <Typography variant="body1" sx={{ color: accentColor, mt: 2 }}>Uw e-mail is verzonden! We nemen zo snel mogelijk contact met u op.</Typography> }
        </Box>
      </Box>
    </Container>
  );
};

export default ContactForm;
