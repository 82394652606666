import { Component } from "react";
import { GlobalDataStore, storeSubscription } from "../../data/data-store";
import { Box, Typography } from "@mui/material";
import { styles } from "../styles/main-styles";


interface Props {
    onServerStatusChange?: (status: boolean) => void;
}

export class ServiceIndicatorComponent extends Component<Props> {  //TODO do we need a control?

    public render(){
        return ( 
            <Box sx={{...styles.serverStatus.container, display: GlobalDataStore.getState().serverRunning? "none" : "inherit"}}>
                <Typography variant="h6" color="text.secondary" sx={styles.serverStatus.text}>De server is momenteel niet bereikbaar. Probeer het later opnieuw.</Typography>
            </Box>
        );
    }

    public componentDidMount(): void {
        this.checkServerStatus();  //TODO global?
    }

    private async checkServerStatus(){ 
        const storeState = GlobalDataStore.getState();
        const serverDown = ()=>{
            console.log("Server down: REASON_UNKNOWN");
            if(!storeState.serverRunning) return;
            this.props.onServerStatusChange?.(false);
            storeState.setServerRunning(false);
        }
        try{
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/service`);     
            if (response.ok) {
                if(storeState.serverRunning) return;

                storeState.setServerRunning(true);
                this.props.onServerStatusChange?.(true);
            } else {
                serverDown();
            }
        } catch(e){
            console.log(e);
            console.log(`Server is niet bereikbaar, status: ${e}`);
            serverDown();
        }   
    }
}
export const ServiceIndicator = storeSubscription(ServiceIndicatorComponent,GlobalDataStore); 