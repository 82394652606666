import { Box, IconButton, TextField, Typography } from "@mui/material";
import { accentColor, styles } from "../../styles/main-styles";
import { Constants } from "../../../data/constants";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useRef } from "react";
import { File } from "../../../data/models/file";
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import imageCompression from 'browser-image-compression';

export function ChatInput(
    {value, onChange, placeholder, onSubmit, canType = false, attachmentEnabled = false, onAttachmentSubmit}:
    {value:string, onChange:(val:string)=>void, placeholder:string, onSubmit:()=>void, canType?:boolean, attachmentEnabled?:boolean, onAttachmentSubmit?:(file: File)=>void}
){

    const inputRef = useRef<HTMLInputElement | null>(null);


    const handleInput = (event: any)=>{
        const isEnter = event.key === 'Enter';
        if(!canType) {
            event.preventDefault();
            return;
        } 
        if(isEnter && value.trim().length<1){ 
            event.preventDefault();
            return;
        };
        if(event.shiftKey) return;
        if(isEnter) {
            event.preventDefault();
            onSubmit();  
        }
    }

    const handleFileChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1, //TODO make global setting
                maxWidthOrHeight: 800,
                useWebWorker: true
            });
            const reader = new FileReader();
            reader.onloadend = () => {
                onAttachmentSubmit?.(new File(file.name as string, reader.result as string));
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    let chatBoxStyle = {...styles.bottomBar.chatBox}
    //@ts-ignore
    if(attachmentEnabled) chatBoxStyle.paddingLeft = 0;
    return (
        <Box sx={styles.bottomBar.inputContainer}>
            <IconButton
                disableRipple={true}
                sx={{...styles.bottomBar.sendButton, display: attachmentEnabled? "inherit" : "none", pl:2}}
                size="large"
                onClick={() => inputRef.current?.click()}
                color="inherit"
                aria-label="menu">
                <input type="file" style={{display:"none"}} ref={inputRef} accept="image/*" onChange={(event)=>handleFileChange(event)}/>
                <AttachFileIcon/>
            </IconButton>
        
            
            <Box sx={styles.bottomBar.inputBar}>
                <TextField
                    variant="standard"
                    multiline={true}
                    style={{ flexGrow: 1 }}
                    InputProps={{
                        disableUnderline: true,
                        style: chatBoxStyle,
                        onKeyDown: event => handleInput(event)
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                    }}
                    value={value}
                    placeholder={placeholder || Constants.INPUT_PLACEHOLDER} />
            </Box>   
            <IconButton
                disableRipple={true}
                style={styles.bottomBar.sendButton}
                size="large"
                onClick={() => onSubmit()}
                color="inherit"
                aria-label="menu">
                <SendIcon />
            </IconButton>   
        </Box>
    )
}

export function AttachmentBar(){
    const parentStyle = {
        // display: File.hasValue(this.state.imageData)? "flex" : "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height:32,
        p:2,
        width:"100%",
    }
    const containerStyle = {
        display: "flex",
        width: {
            xs:"90%",
            md:"800px",
        }
    }
    const childStyle = {
        height:8,
        width:200,
        p:2,
        ml:1,
        borderRadius:"2em",
        backgroundColor:accentColor,
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
    }
    const textStyle = {
        mr:2,
        whiteSpace:"nowrap",
        overflow:"hidden",
        textOverflow:"ellipsis",
        flexGrow:1
    }
    return (
        <Box sx={parentStyle}>
            <Box sx={containerStyle}>
                <Box sx={childStyle}> 
                    <ImageIcon sx={{height:16}}/>
                    <Typography variant="body2" component="div" sx={textStyle}>HAllo-ewtwefg.png</Typography>
                    {/* <Typography variant="body1" component="div" sx={textStyle}>{this.state.imageData.name}</Typography> */}
                    <IconButton onClick={()=>{}}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}
