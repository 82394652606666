import { Box, CardContent, Typography } from "@mui/material";
import { navigate } from "../router-config";

export function ProductCard(
    {
        title,
        titleExtra,
        titleExtraColor = "inherit",
        description, 
        backgroundColor, 
        click,
        img,
        disabled = false,
        url = "/",
        size,
    }:{
        title?:string, 
        titleExtra?: string,
        titleExtraColor?: string,
        description?:string,
        backgroundColor?: string, 
        img?:string,
        disabled?:boolean,
        url?:string,
        size?:{
            sx?:number;
            md?:number;
            xl?:number;
        },
        click?: ()=>void | null
    }): JSX.Element {

    const style = {
        minWidth: 200, 
        padding: {xs:0, md:2, xl: 0},
        height:{
            xs: "auto",
            xl: "100%",
        },
        borderRadius:"1em",
        backgroundColor,
        cursor: "pointer",
        overflow: "hidden",
        transition:"all 0.3s ease-in-out",
        opacity: disabled? 0.5 : 1,
        '&:hover': {
            transform: disabled? "scale(1)" :"scale(1.05)",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        },
    }
    const clickAction = click? ()=>click() : ()=>{navigate(url)}; //TODO h4 op desktop
    return (
        <Box sx={style} onClick={clickAction}>
            <Box sx={{ display: 'flex', flexDirection: {xs: "column", md: "row", xl:"row"}, maxWidth:{ sx: 300, md:"100%" }, minHeight: 300, maxHeight:800, height:"100%", overflow: "hidden" }}>
                <CardContent sx={{flex:5, padding: {xs:4, xl: 6}}} >

                    <Typography variant="h5" component="div" sx={{mb:2, pr:2, mt: {xs:2, md:0},  color:"white", fontWeight:"bold", display: {xl:"none", md:"inherit"} }}>
                        {title} {titleExtra !== undefined && <Typography variant="body1" sx={{color:titleExtraColor, m:0, p:0}}>{titleExtra}</Typography>} 
                    </Typography>

                    <Typography variant="h4" component="div" sx={{mb:4, pr:2, mt: 2, color:"white",  display: {xl:"inherit", md:"none", xs:"none"} }}>
                        {title} {titleExtra !== undefined && <Typography variant="body1" sx={{color:titleExtraColor, m:0, p:0}}>{titleExtra}</Typography>} 
                    </Typography>
                        
    
                    <Typography variant="body1" color="text.secondary" sx={{mb:{xs:2, md:8}, pr:2, color:"white"}}>
                        {description}
                    </Typography>
                </CardContent>
                {img? <CardImage img={img} /> : <></>}
            </Box>
        </Box>
    );
}

function CardImage({img}:{img:string}): JSX.Element {
    const dimensionsMin = { 
        xs: 100,
        md: 200,
        xl: 300,
    }
    const dimensionsMax = { 
        xs: 200,
        md: 400,
        xl: 600,
    }

    const indicator ={
        xs:"transparent",
        md:"transparent",
        xl: "transparent"
    }
    return (
        <>
        <Box sx={{flex:5, position:"relative", overflow:"hidden", display: {xs: "none", md: "none", xl: "none", xxxl: "inherit"}}}>
            <img                      
                src={img}
                alt="linear demo"
                width={"110%"}
                height={"110%"}
                className="absolute -right-[20%] -bottom-[35%] object-contain rounded-2xl"
            /> 
        </Box>
        <Box sx={{backgroundColor:indicator, pl:{xl:4,md:2}, pr:{xl:4,md:2}, flex:4, justifyContent:"center", alignItems:"center", overflow:"hidden", display: {xs: "none", md: "inherit", xl: "inherit", xxxl: "none"}}}> 
            <Box
                component="img"                      
                src={img}
                alt="linear demo"
                sx={{maxWidth:dimensionsMax, maxHeight:dimensionsMax, minWidth:dimensionsMin, minHeight:dimensionsMin, objectFit:"contain"}}
            /> 
        </Box>

        <Box sx={{flex:10, borderRadius:"20px 20px 0px 0 ", justifyContent:"center", alignItems:"center", overflow:"hidden", position:"relative", display: {xs: "inherit", md: "none", xl: "none", xxxl: "none"}}}>
            <Box
                component="img"                      
                src={img}
                alt="linear demo"
                sx={{width:"100%", height:"100%", position:"relative", objectPosition:"top 100%"}}
            /> 
        </Box>
        
        </>
    );
}