import React, { Component, useState } from 'react';
import { Box, ThemeProvider } from '@mui/material';
import {accentColor, containerColor, pageColor, theme} from "./view/styles/main-styles";
import { router, history } from './view/router-config';
import { Error } from './view/pages/error';
import { Utils } from './utils/utils';

class App extends Component {

    private historyListener: any= null;
    public state = {
      currentComponent: null,
      animate: false,
      initialRender: true,
      lastPath: "/",
    };

    constructor(props: any) {
      super(props);
      if(Utils.isNotLocalhost(window.location.href)){
        console.log = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else console.log("localhost logging enabled")
    }

    public componentDidMount() {
      this.historyListener = history.listen((update: any) => {
        if(update.location.hash && update.location.pathname === this.state.lastPath) return;
        this.resolveRoute(update.location.pathname);
      });
      this.resolveRoute(history.location.pathname);
    }

    public componentWillUnmount(): void {
      this.historyListener?.();
    }

    private resolveRoute(pathname: string): void {
      if(!this.state.initialRender) {this.setState({animate:true}) } else this.setState({initialRender:false});
      setTimeout(()=>{
        router.resolve({ pathname: pathname }) //window.location.pathname
        .then(component => this.setState({ currentComponent: component, lastPath: pathname}))
        // eslint-disable-next-line react/jsx-no-undef
        .catch(error => this.setState({ currentComponent: <Error/> })); //TODO error page
      },500)

    }

    public render() {
        return (
            <ThemeProvider theme={theme}>
                {this.state.currentComponent}
                <Ripple show={this.state.animate} onDone={()=>this.setState({animate:false})}/>
            </ThemeProvider>
        );
    }
}

export default App;


function Ripple(
  {show, onDone} : {show:boolean, onDone?:()=>void}
){
  const [scale, setScale] = useState(0);
  const [opacity, setOpacity ] = useState(100);
  const animate = (cb: ()=>void, delay?:number)=>setTimeout(()=>cb(), delay||0);

  const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    opacity: `${opacity}%`,
    zIndex: 9999,
    backgroundColor: containerColor,
    clipPath: `circle(${scale}% at 50% 50%)`,
    transition: !show? "none" : "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
  }
  if(show)
    animate(()=>{
      setScale(80);
      animate(()=>{
        setOpacity(0);

        animate(()=>{
          setScale(0);
          setOpacity(100);
          onDone?.();
        },400);
      },500);
    });

  return (
    <Box sx={style}/>
  );
}