import { Box, width } from "@mui/system";
import { transform } from "typescript";
import { accentColor } from "../../styles/main-styles";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { url } from "inspector";
import { navigate } from "../../router-config";

export function MobileNavigation({show, onClose}: {show: boolean, onClose?:()=>void}){

    const style = {
        position: "fixed",
        top:show? 0 : 8,
        right:show? 0 : 16,
        height:"100vh",
        width:"100vw",
        backgroundColor: accentColor,
        overflow: 'hidden',
        pointerEvents: show ? 'auto' : 'none',
        opacity: show? `100%` : `0%`,
        zIndex: 9999,
        clipPath: show ? 'circle(100% at 50% 50%)' : 'circle(0% at calc(100% - 16px) 16px)',
        transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
    }

    const subContainerStyle = {
        position: "fixed",
        opacity: show? `100%` : `0%`,
        top:"50%", left:"50%",
        transform: "translate(-50%,-50%)",
        width: show? "100%" : "0",
        height: show? "100%" : "0",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        zIndex:99999,
        transition: "all ease-in-out 0.1s",
    }

    const closeButtonStyle = { 
        position: "fixed",
        top:0, right:0,
        mt:{ xs:2, md:2.5 }, mr: { xs:2, md:3 },
        zIndex:999999,
        pointerEvents: show ? 'auto' : 'none',
        display: show? "inherit" : "none",
    }

    const listItemStyle ={
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: show? `100%` : `0%`,
        // m:2,
        // p:4,
        cursor: "pointer",
        //pt:show? 0 : 2,
        pointerEvents: show ? 'auto' : 'none',
        letterSpacing: ".5rem" ,
        fontSize: show? "1.3rem" : "1.2rem",
        transition: show?"all ease-in-out 0.5s" : "all ease-in-out 0.1s",
        transitionDelay: show? "0.1s" : "0s",
        "&:hover": {
            color: "rgba(0,0,0,0.8)",
        }
    }

    const contactStyle = {
        color:"black",
        pointerEvents: show ? 'auto' : 'none',
        backgroundColor:"white",
        borderRadius:"12em",
        fontSize: "0.8em",
        letterSpacing: "normal",
        pl:8, pr:8, mt:4
    }

    useEffect(() => { 
        if(show){
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.overflow = "auto";
        }
    }, [show]);

    const goTo = (url: string) =>{
        onClose?.();
        document.body.style.overflow = "auto";
        navigate(url);
    }

    return (
        <>
            <Box sx={style}/>
            <IconButton sx={closeButtonStyle} onClick={()=>onClose?.()}>
                    <CloseIcon />
            </IconButton>
            <Box sx={subContainerStyle}>                             
                <Box sx={listItemStyle} onClick={()=>goTo("#services")}><p>DIENSTEN</p></Box>       
                <Box sx={listItemStyle} onClick={()=>goTo("#assistants")}><p>AI DEMO'S</p></Box>
                <Box sx={listItemStyle} onClick={()=>goTo("#use-cases")}><p>AI USE CASES</p></Box>
                <Box sx={listItemStyle} onClick={()=>goTo("#about")}><p>OVER ONS</p></Box>
                <Box sx={{...listItemStyle, ...contactStyle}} onClick={()=>goTo("#contact")}><p>CONTACT</p></Box>
            </Box>
        </>

    );
}