import {Box,IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Toolbar, Typography } from "@mui/material";
import { Component } from "react";
import {  styles } from "../styles/main-styles";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PublicIcon from '@mui/icons-material/Public';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Constants } from "../../data/constants";
import { ContactButton } from "./common/contact-button";
import { navigate } from "../router-config";

interface IChatToolbarProps {
    onMenuClick: () => void;
    title: string;
    mobileTitle: string;

    mobileMenu?: boolean
    singplePageLayout?: boolean
    logo?: boolean
}

export class AppToolbar extends Component<IChatToolbarProps> {

    public static defaultProps = {
        mobileMenu: false,
        singplePageLayout: false,
        logo: false
    }

    public state = { 
        showContactModal: false
    }

    public render(){
        let parentStyle = this.props.singplePageLayout? {
            ...styles.chatToolbar.toolbar,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column"
        } :{...styles.chatToolbar.toolbar};

        let style = {display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", width: this.props.singplePageLayout? { xs:"100%", md:"800px" }: "100%"}
        return (
            <Toolbar sx={parentStyle}>
                <Box sx={style}>
                    {this.props.mobileMenu? this.renderMobileMenu() : <></>}
                    {this.props.logo? <Box component={"img"} src="pa-logo.png" alt="" sx={styles.landingsToolbar.image} onClick={()=>navigate("/")}/> : <></>}

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xl: 'inherit', xs: 'none' } }}>{this.props.title}</Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xl: 'none', xs: 'inherit' } }}>{this.props.mobileTitle}</Typography>

                    {this.renderMenu()}
                    {this.renderContactModal()}
                </Box>
            </Toolbar>
        )
    }

    private renderMobileMenu(){
        return (
            <IconButton sx={{ display: { xl: 'none', xs: 'inherit' } }} aria-label="delete" onClick={this.props.onMenuClick}>
                <DragHandleIcon />
            </IconButton>
        )
    }

    private renderMenu(){ //TODO use <ContactButton/> or <ContactDialog/>
        return (
            <>
                {/* <Button sx={{
                    borderRadius: 12,
                    //backgroundColor: "rgba(88, 196, 220,0.2)",
                    textTransform: "none",
                    pl:2,pr:2,mr:0,
                    display: { xl: 'inherit', xs: 'none' },
                    // /color: accentColor
                }}  color="inherit" onClick={this.props.onMenuClick}>Implementaties</Button> */}
{/* 
                <Button sx={{
                    borderRadius: 12,
                    backgroundColor: "rgba(88, 196, 220,0.2)",
                    textTransform: "none",
                    pl:2,pr:2,
                    //display: { xl: 'inherit', xs: 'none' },
                    color: accentColor
                }} color="inherit" onClick={()=>this.setState({showContactModal: true})}>Contact</Button>
                 */}
                 <ContactButton/>
                {/* <ChatNavigationMenu sx={{
                        display: { xl: 'none', xs: 'inherit' }
                    }}
                    onContactClick={()=>this.setState({showContactModal: true})}
                    onImplementationClick={()=>this.setState({showImplementationModal: true})}
                /> */}
            </>
        );
    }

    private renderContactModal(){
        return (        
        <Modal open={this.state.showContactModal} onClose={()=>this.setState({showContactModal: false})} >
            <Box sx={styles.chatToolbar.modalSX}>
                <Typography id="modal-modal-title" variant="h4" component="h2">Pixel Ape</Typography>
                <List>                          
                    {this.renderListItem(<AlternateEmailIcon/>, Constants.COMPANY.EMAIL, ()=>this.openMailClient())}
                    {this.renderListItem(<LocalPhoneIcon/>, Constants.COMPANY.PHONE, ()=>this.callPhone())}
                    {this.renderListItem(<PublicIcon/>, Constants.COMPANY.WEBSITE, ()=>this.openWebsite())}
                </List>
            </Box>
        </Modal>
        );
    }

    private renderListItem(icon: any, text: string, onClick: () => void){
        return (<ListItem disablePadding>
            <ListItemButton onClick={onClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>);
    }

    private openMailClient(){
        window.location.href = `mailto:${Constants.COMPANY.EMAIL}?subject=AI Integratie&body=`;
    }

    private callPhone(){
        window.location.href = `tel:${Constants.COMPANY.PHONE}`;
    }

    private openWebsite(){
        window.location.href = Constants.COMPANY.WEBSITE;
    }
}