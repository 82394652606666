import { Box, Button, CircularProgress, Snackbar, Typography } from "@mui/material"
import { AppToolbar } from "../components/app-toolbar"
import { Component, createRef, RefObject } from "react"
import { File as CustomFile } from "../../data/models/file";
import { containerColor, overlayColor, styles } from "../styles/main-styles";
import imageCompression from "browser-image-compression";
import { MessageServiceInstance } from "../../domain/services/message.service";
import { Tool } from "../../data/models/tool";
import { Role } from "../../data/models/gpt-message";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { getDocument, GlobalWorkerOptions  } from "pdfjs-dist";
import { ServiceIndicator } from "../components/service-indicator";

export class DocumentReader extends Component {

    private systemMessages = [`Je bent een document scanner. Je reageert alleen in het Nederlands. Textopmaak is altijd in Markdown`];
    private inputRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    public state = {
        document: CustomFile.empty(),
        documentText:"",
        loading: false,
        stateInfo:"",
        carInfo:"",
        snackBarOpen: false,
        snackBarMessage:"",
    }

    private contentContainertStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        pt: 5,
        flex:1,
        flexGrow: 1,
    }

    public componentDidMount(){
        GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.mjs';
    }

    public render(){
        return (
            <Box sx={{height:"100%", width:"100%", position:"absolute", display:"flex", flexDirection:"column"}}>
                <AppToolbar title={"Document Reader"} mobileTitle={"Document Reader"} logo={true} singplePageLayout={true} onMenuClick={()=>{}}/>
                <Box sx={this.contentContainertStyle}>
                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",width:{xs: "100%", md:600 }, pl:4,pr:4, boxSizing:"border-box"}}>    
                        {this.renderDescription()}
                        <Box sx={{position:"relative", height: {xs: 300, md: 700, xl:900}, width: {xs: "100%",  md: 600}, mt:4, p:2, overflow: "auto", border:`1px solid ${overlayColor}`, backgroundColor: containerColor}}>
                            {this.state.loading && this.renderLoader()}
                            {this.state.documentText && !this.state.loading && <Markdown remarkPlugins={[remarkGfm]}>{this.state.documentText}</Markdown>} 
                        </Box>
                    </Box>
                    <Box sx={{pb:4}}>
                        {this.renderInputBox()}
                    </Box>
                </Box>
                <Snackbar
                    open={this.state.snackBarOpen}
                    autoHideDuration={6000}
                    onClose={()=>this.setState({snackBarOpen:false})}
                    message={this.state.snackBarMessage}
                    ContentProps={styles.mainPage.snackbar}
                />
                <ServiceIndicator />
            </Box>
        )
    }



    private renderLoader(){
        return (
            <Box sx={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>
                <CircularProgress />
            </Box>
        );
    }

    private renderDescription(){
        return (
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",boxSizing:"border-box"}}>
                <Typography variant="body1" sx={{mt:0}}>Klik op de onderstaande knop om een PDF of afbeelding te uploaden.</Typography>
                <Typography variant="body1" sx={{mt:0}}>Bonnetjes werken ook!</Typography>
            </Box> 
        )
    }

    private renderInputBox(){
        return (
            <>
                <input type="file" style={{display:"none"}} ref={this.inputRef} accept="image/*,.pdf"  onChange={(event)=>this.handleFileChange(event)}/>
                <Button 
                    disabled={this.state.loading}
                    sx={{...styles.mainPage.leftContainer.saveButton, width:200, mt:4}} size="large" disableElevation variant="contained"
                    onClick={()=>this.inputRef.current?.click()}>
                        Uploaden
                </Button>
            </>
        );
    }

    private async handleFileChange(event: React.ChangeEvent<HTMLInputElement>){
        let file = event.target.files?.[0];
        if(!file) return;
        if (file.type === 'application/pdf') {
            const pdfDataUrl = await this.convertPdfToImage(file);
            file = await this.dataURLtoFile(pdfDataUrl, 'converted-image.png');
        }

        file = await imageCompression(file, {
            maxSizeMB: 1, //TODO make global setting
            maxWidthOrHeight: 800,
            useWebWorker: true
        });

        const reader = new FileReader();
        reader.onloadend = async () => {
            try {
                if(!file) return; //tODO wtf?
                const document = new CustomFile(file.name as string, reader.result as string); 
                this.setState({document: document, loading:true});

                const aiResult = await this.doAIRequest(document);
                console.log(aiResult)
                
                const toolData = JSON.parse(aiResult.tools[0].function.arguments);
                console.log(toolData);

                this.setState({
                    loading:false,
                    documentText: toolData.data,
                });


            } catch (e) {
                console.log(e);
                this.setState({loading:false, snackbarOpen:true, snackbarMessage: "Er is een onbekende fout opgetreden"});
            }
        };
        reader.readAsDataURL(file);
    }

    private async doAIRequest(doc: CustomFile){
        MessageServiceInstance.setupStacked(this.systemMessages,0,[new Tool("get_car_info", this.tool() ,args=>{})]);
        const request = await MessageServiceInstance.GTPPost("", doc, Role.USER);
        return await request.json() as any;
    }

    private tool() {
        return {
          "type": "function",
          "function": {
            "name": "get_document_info",
            "description": "Gets the text from a PDF or image file and returns it as organised Markdown", //" Asks for all the fields that are required for an invoice and after that creates an invoice in JSON.",
            "parameters": {
              "type": "object",
              "properties": {
                "data": {
                  "type": "string",
                  "description": "The text of the document in organised Markdown format"
                },
                "reply": {
                  "type": "string",
                  "description": "What you want to say to the user"
                }
              },
              "required": ["data", "reply",]
            }
          }
        }
    }

    private async convertPdfToImage(pdfFile: File): Promise<string> {
        const pdfData = await pdfFile.arrayBuffer();
        const pdf = await getDocument({ data: pdfData }).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 2 });
        
        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const context = canvas.getContext('2d');
        
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };
        //@ts-ignore
        await page.render(renderContext).promise;
        return canvas.toDataURL('image/png');
    }

    private dataURLtoFile(dataurl: string, filename: string) {
        const arr = dataurl.split(',');
        //@ts-ignore
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
}     