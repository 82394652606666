import { Component } from "react";
import { accentColor, containerColor, overlayColor, pageColor, styles } from "../styles/main-styles";
import { Box, Button, Grid, IconButton,Link,Typography} from "@mui/material";
import { FlipWords } from "../components/aceternity/flip-words";
import { LandingToolbar } from "../components/landing-toolbar";
import { ProductCard } from "../components/product-card";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AI_NAME, Constants } from "../../data/constants";
import { WaveDividerFancy } from "../components/website/fancy/wave-divider";
import { Footer } from "../components/website/footer";
import { Title } from "../components/website/title";
import ContactForm from "../components/website/contact-form";
import { Utils } from "../../utils/utils";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { navigate } from "../router-config";
import InfiniteCarousel from "../components/website/fancy/infinite-carousel";
import { TextLink } from "../components/common/text-link";

export class Landing extends Component {

  public render(){ //TODO this breaks the fucking scroll to target but not sure why    
    return (
      <div style={styles.mainPage.pageContainer}>
          <div style={{...styles.mainPage.contentContainer}}>
              <div style={styles.mainPage.rightContainer.container}>
                  <LandingToolbar click={()=>navigate("#contact")} large={true} />
                  <Box sx={{mt:8}}/>
                  <FlipWordsHero/>
                  <IconButton aria-label="scroll down" className="pointdown" onClick={()=>navigate("#assistants")}><KeyboardArrowDownIcon/></IconButton>

                  {this.renderServicesSection()}  
                  {this.renderUseCasesSection()}   
                  {this.renderAssistentsSections()}
                  {/* {this.renderAboutSection()}  */}
                  {this.renderContactSection()}

              </div>    
          </div>
          <Footer color={pageColor}/>
      </div>
    );
  }

  private renderAssistentsSections(){
    return (
      <>
        <WaveDividerFancy style={{marginBottom:0, paddingBottom:0}}/>
                  
        <Box id="assistants" sx={{ display: { md: 'inherit', xs: 'flex' }, flexDirection: 'column', justifyContent:"center", pt:8, alignItems:"center", width: "100%", backgroundColor: containerColor }}>
          <Title sx={{pl:2,pr:4,mb:{sx:2, md:4}, mt:{sx:0, md:4}}} mobileComponent={"h5"} desktopComponent={"h4"}>AI Demonstraties</Title>
          <Box sx={{ display: { md: 'inherit', xs: 'flex' }, flexDirection: 'column', width: { md: '80%', xl: "70%", xxl: "60%" }, backgroundColor: containerColor, paddingLeft:4, paddingRight:4, mb:16 }}>
            {this.renderAssistantProducts()}
            {this.renderVisionProducts()}
          </Box>
        </Box>
      </>
    )
  }


  private renderUseCasesSection(){ //TODO match with productcards
    const containerStyle = {display: "flex", flexDirection: "column"};
    const itemParentStyle = {display: "flex", alignItems:"center", justifyContent:"center", widht:"100%", cursor:"pointer"};
    const itemStyle = {p:{xs:4,md:6},alignItems:"center", justifyContent:"center", borderRadius:"1em", backgroundColor: containerColor, height:"100%", boxSizing:"border-box" };
    const itemTitleStyle = {mb:0, pr:0, mt: 2, fontWeight: {xs: "bold", md: "inherit"}};
    const sectionStyle = {mt:2,mb:2}
    const sectionTitleStyle = {mb:2, mt:{xs:2, md:4}}
    const SEOLinkStyle = {color: accentColor, textDecoration: "none", textTransform:"none", fontSize:"inherit", padding:0, display:"inline", cursor:"pointer"} as any;

    const renderItem = (title: string, sections: Array<{title: string, description: string}>) =>{
      return (
        <Grid sx={itemParentStyle} item xs={12} md={6}>
        <Box sx={itemStyle}>
          <Title sx={itemTitleStyle} mobileComponent="h5" desktopComponent="h4">{title}</Title>
          {
            sections.map((section, index) => (
              <Box key={index} sx={sectionStyle}>
                <Title sx={sectionTitleStyle} mobileComponent="h6" desktopComponent="h5">{section.title}</Title>
                <Typography component="div" variant="body1">{section.description}</Typography> <br/>
              </Box>
            ))
          }
        </Box>
      </Grid>
      )
    }

    return (
      <>
        {this.renderSection("AI Use Cases", pageColor,containerColor, "use-cases", true,
          <Box sx={containerStyle}>
            <Typography component="p">
            Met onze geavanceerde AI-modellen, variërend van taalverwerking tot machine learning en data-analyse, zijn de mogelijkheden vrijwel eindeloos. Of het nu gaat om het automatiseren van repetitieve taken, het analyseren van complexe datasets of het personaliseren van klantinteracties, AI kan een sleutelrol spelen in elk aspect van jouw organisatie. <br/><br/>

            Om een beter beeld te geven van hoe AI je kan helpen, hebben we een aantal veelvoorkomende use cases samengesteld die we in de praktijk zien terugkomen. Deze voorbeelden tonen de uiteenlopende toepassingen van AI en laten zien hoe je bedrijf direct voordeel kan halen uit het inzetten van AI-technologie.<br/><br/>

            Als je geïnteresseerd bent in een van onze diensten of <TextLink href="#assistants">demo's</TextLink>, aarzel dan niet om vrijblijvend <TextLink href="#contact">contact</TextLink> met ons op te nemen. We helpen je graag om de juiste AI-oplossing voor jouw specifieke behoeften te vinden en samen aan de slag te gaan met innovatieve technologieën.
            </Typography>

            <Grid container spacing={2} sx={{mt:4}}>

              {renderItem("Innovatie",[
                {title: "Implementatie in een baanbrekend product of project", description: "Onze AI maakt het mogelijk om te communiceren met jouw code project en innovatie te stimuleren met geavanceerde technologieën."},
                {title: "AI-gedreven Prototyping", description: "Versnel de ontwikkeling van prototypes met AI-tools die het mogelijk maken om snel ideeën te valideren en de implementatie te optimaliseren."},
              ])}
          
              {renderItem("Strategie",[
                {title: "Competitie Analyse", description: "Blijf in een strategisch voordeel door alle extern beschikbare gegevens van concurenten te volgen en te analyseren."},
                {title: "Industrie En Branch Analyse", description: "Blijf in een strategisch voordeel door alle extern beschikbare gegevens van concurerenten te volgen en te analyseren."},
              ])}

              {renderItem("Marketing",[
                {title: "Brand Content Generatie", description: "Informeer jouw doelgroep met dynamische en gepersonaliseerde content gegenereerd door onze AI. Dit bespaart tijd en verhoogt de effectiviteit van je merkboodschappen."},
                {title: "Social Media, Blog en Website Automatisering", description: "Automatiseer je online aanwezigheid met AI-gegenereerde berichten, blogs en website-inhoud, waardoor je marketing consistent en efficiënt wordt uitgevoerd."},
              ])}

              {renderItem("Operations",[
                {title: "Complexe Data Analyse", description: "Ontdek verborgen inzichten in grote datasets. Onze AI voert diepgaande analyses uit om patronen te vinden en nieuwe mogelijkheden te ontdekken."},
                {title: "Q&A Integratie", description: "Verbeter je klantcommunicatie met een slimme Q&A-tool die vragen automatisch beantwoordt op basis van jouw databronnen. Onze AI leert continu bij om betere antwoorden te geven."},
              ])}

              {renderItem("Support",[
                {title: "Klantenservice Automatisering", description: "Ondersteun klanten 24/7 met AI-aangedreven klantenservice. Automatiseer veelgestelde vragen en verbeter de klanttevredenheid door snelle en consistente antwoorden te geven."},
                {title: "AI-gedreven Ondersteuning voor Medewerkers", description: "Bied je medewerkers toegang tot een slimme assistent die hen ondersteunt bij complexe taken, waardoor hun efficiëntie en nauwkeurigheid wordt verhoogd."},
              ])}

            </Grid>
          </Box>
        )}
      </>
    )
  }

  private renderWhySection(){
    return (
      <>
        - Gebruik van de laatste technologie's en de mogelijkheid om deze technologie's te integreren in de bedrijfsprocessen.
        - Gepersonaliseerde aanpak en aansluiting op bedrijfsprocessen of klantenservices.
        - Geautomatiseerde taken met jullie eigen data
        - Data veiligheid. Data behoort tot juillie en wij zullen geen AI trainen met jullie data of op een andere manier gebruiken/verwerken
        - Enterprise-grade beveiliging. Wij houden de gegevens van jullie klanten veilig en beschermen jullie privacy
      </>
    )
  }

  private renderAboutSection(){
    return (
      <>
        {this.renderSection("Over Ons",containerColor,pageColor, "about",  true,
          <Box>
            <Grid container spacing={{xs:2,md:8}}sx={{mt:0}}>
              <Grid item xs={12} md={6}>
                <Box component={"img"} src={"flower.webp"} sx={{width:"100%", height:"100%", borderRadius:"1em"}}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="p">
                  Wij zijn een team van technologie-enthousiastelingen die innovatieve software en AI-oplossingen creëren.<br/><br/>
                  Onze passie drijft ons om grenzen te verleggen en de digitale toekomst vorm te geven.<br/><br/>
                  Met een focus op creativiteit, kwaliteit en samenwerking, leveren wij op maat gemaakte oplossingen die de verwachtingen van onze klanten overtreffen.<br/><br/>
                  We omarmen innovatie en blijven voorop lopen in een snel veranderende technologie-industrie.<br/><br/>
                  Stuur ons een <span style={{color:accentColor, cursor: "pointer"}} onClick={()=>navigate("#contact")}>mailtje</span> en laten we samen de toekomst vormgeven.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }

  private renderServicesSection(){
    const titleStyle = {
      mt:4,
      mb:2,
    }
    return (
      <>
        {this.renderSection("Diensten", containerColor, pageColor, "services", true,
          <Box>
            <Typography component="p">Bij ons kunt u terecht voor een uitgebreid scala aan innovatieve diensten die zijn ontworpen om uw bedrijfsprocessen te transformeren en duurzame groei te stimuleren, met of zonder de integratie van AI-technologieën. Ons doel is om technologie naadloos in te passen in uw bedrijfsstrategie, zodat u klaar bent voor de toekomst.</Typography>
            <Typography sx={titleStyle} variant="h5">Softwareontwikkeling</Typography>
            <Typography component="p">Ons team van ervaren ontwikkelaars creëert op maat gemaakte softwareoplossingen die naadloos aansluiten op uw specifieke behoeften. Van webapplicaties tot mobiele apps, wij leveren robuuste en schaalbare oplossingen die uw bedrijf naar een hoger niveau tillen.</Typography>

            <Typography sx={titleStyle} variant="h5">AI-oplossingen</Typography>
            <Typography component="p"> Wij specialiseren ons in het ontwikkelen van geavanceerde AI-oplossingen die uw bedrijfsprocessen optimaliseren en automatiseren. Van machine learning tot natuurlijke taalverwerking, wij bieden intelligente systemen die inzicht en efficiëntie bevorderen.</Typography>

            <Typography sx={titleStyle} variant="h5">Consultancy</Typography>
            <Typography component="p">Onze experts bieden strategisch advies en begeleiding om uw technologische uitdagingen aan te pakken. Wij helpen u bij het identificeren van kansen, het optimaliseren van uw IT-infrastructuur en het implementeren van de nieuwste technologieën.</Typography>

            
            <Typography sx={titleStyle} variant="h5">Onderhoud en Ondersteuning</Typography>
            <Typography component="p">Wij bieden uitgebreide onderhouds- en ondersteuningsdiensten om ervoor te zorgen dat uw systemen altijd optimaal presteren. Onze proactieve benadering garandeert minimale downtime en maximale efficiëntie.</Typography>

            
            <Typography sx={titleStyle} variant="h5">Training en Educatie</Typography>
            <Typography component="p">Wij bieden training en educatie aan om uw team te helpen het maximale uit onze oplossingen te halen. Van workshops tot uitgebreide trainingsprogramma's, wij zorgen ervoor dat uw team de kennis en vaardigheden heeft om succesvol te zijn.</Typography>
          </Box>
        )}
        {/* <Box sx={{overflow:"hidden", width:"100vw", display:"block", backgroundColor: containerColor}}>
          <InfiniteCarousel speed={0.5} direction="left" pauseOnHover={true} >
          <img alt="te" src={"flower.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          <img alt="te" src={"flower.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          <img alt="te" src={"flower.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          <img alt="te" src={"flower.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          <img alt="te" src={"flower.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          <img alt="te" src={"abstract-b1.webp"} style={{width:"100px", height:"100px", borderRadius:"1em"}}/>
          </InfiniteCarousel>
        </Box> */}
      </>
    )
  }

  private renderContactSection(){
    const icon = {height:56, fontSize:"1.8em"};
    const renderListItem = (icon: any, text: string, aria: string, onClick: () => void) =>{
      return (
          <Box onClick={onClick} sx={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", m:1, p:2, backgroundColor: containerColor, borderRadius:"1em", cursor:"pointer"}}>
            <IconButton aria-label={aria} onClick={onClick} focusRipple={false} sx={{height:56, mb:1}}>
                    {icon}
            </IconButton>
            <Typography variant="body1" sx={{color:"white"}}>{text}</Typography>
        </Box>);
    }
    return (
      <>
        {this.renderSection("Contact", pageColor, containerColor, "contact", true,
          <Grid container spacing={4}sx={{mt:0}}>
            <Grid item xs={12} xl={8}>
              <ContactForm/>
            </Grid>

            <Grid item xs={12} xl={4}>
              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "top", pl:4, pr:4, pt:{xs:4, md:0}, alignItems: "center", height:"100%", borderRadius: "1em" }}>
                <Typography variant="h6" component="div" align="center" gutterBottom>We zijn ook te bereiken op</Typography>
                {renderListItem(<WhatsAppIcon sx={icon}/>, Constants.COMPANY.PHONE, "Open WhatsApp", ()=>Utils.openWhatsapp())}
                {renderListItem(<AlternateEmailIcon sx={icon}/>, Constants.COMPANY.EMAIL, "Open Email", ()=>Utils.openMailClient())}
                {renderListItem(<LocalPhoneIcon sx={icon}/>, Constants.COMPANY.PHONE, "Open Telefoon", ()=>Utils.callPhone())}
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  private renderSection(title: string, mainColor: string, backgroundColor: string, sectionId: string, useDivider: boolean, children: React.ReactNode){
    return (
      <Box id={sectionId} sx={{width: "100%", backgroundColor: backgroundColor}}>
        {useDivider && <WaveDividerFancy style={{marginBottom:0, paddingBottom:0}} color={mainColor} />}
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: mainColor }}>
          <Box sx={{ display: { md: 'flex', xs: 'flex' }, flexDirection: 'column', justifyContent:"center", pt:4, pb:16, pl:4, pr:4, alignItems:"center",  width: { md: '80%', xl: "70%", xxl: "60%" } }}>
            <Title sx={{pl:2,pr:4, mb:{xs:2, md:4}, mt:{xs:0, md:4}}} mobileComponent={"h5"} desktopComponent={"h4"}>{title}</Title>
            {children}
          </Box> 
        </Box>
      </Box>
    )
  }


  private renderAiProducts(title: string, products:Array<AssistenData>){} //TODO

  private renderAssistantProducts(){
    return (
      <>
        <h4 className="text-left ml-2 mb-0">Assistent Demo's</h4>
        {this.renderProducts(assistantProducts)}
      </>
    );
  }

  private renderVisionProducts(){
    return (
      <>
        <h4 className="text-left ml-2 mb-0 mt-16">Vision Demo's</h4>
        {this.renderProducts(visionProducts)}
      </>
    );
  }

  private renderProducts(products: Array<AssistenData>){
    return (
      <Grid container spacing={2} sx={{mt:0}} >                     
        {products.map((product, index) => ( 
          <Grid key={index} item xs={product.size?.xs || 12}  md={product.size?.md || 6} xl={product.size?.xl || 4}>
            <ProductCard 
              title={product.title} 
              titleExtra={product.titleExtra}
              titleExtraColor={product.titleExtraColor}
              description={product.description} 
              backgroundColor={product.backgroundColor ||containerColor}
              disabled={product.disabled}
              img={product.img}
              url={product.url}
              size={product.size}
              click={product.click}
            />
          </Grid>
      ))}
      </Grid>
     );
  }
}

function FlipWordsHero() {
  const words = ["efficiënter.","slimmer.","sneller.", "minder."];
  return (
    <div className="md:h-[50rem] h-[20rem] md:mt-16 mt-8 flex justify-center items-center px-4">
      <div className="mx-auto font-normal text-neutral-300 text-3xl text-left pl-4 pr-4 md:text-left md:text-5xl xl:text-6xl">
        Werk<FlipWords className="text-accent" words={words} /><br />met AI oplossingen van Pixel Ape
      </div>
    </div>
  );
}

type AssistenData = { 
    title:string,
    titleExtra?:string,
    titleExtraColor?:string,
    description:string,
    disabled?:boolean,
    img?:string,
    size?:{
      xs?:number;
      md?:number;
      xl?:number;
    },
    url?:string,
    backgroundColor?:string,
    click?:()=>void
}

const cardColor = overlayColor;
const assistantProducts : Array<AssistenData> = [
  {
    title: `Allround AI Assistent ${AI_NAME}`, 
    description: `${AI_NAME} is een veelzijdige AI-assistent die naadloos integreert in elk project. 
                  Deze AI kan zowel reageren op gebruikersinput als autonoom handelen, 
                  en heeft de capaciteit om met andere systemen te communiceren. 
                  De demo toont de kracht en veelzijdigheid van ${AI_NAME}. 
                  Met extra maatwerk kan deze assistent perfect worden aangepast aan specifieke taken.`,
    // img: "temp.png", 
    url: "/assistant", 
    backgroundColor: cardColor, 
    size: {xs: 12, md: 12, xl: 8}
  },
  {
    title: "Data Analist",
    description: `Ontdek onze AI Data Analyst, ontworpen om complexe data-analyses eenvoudig en snel te maken. 
                  Deze assistent kan grote datasets verwerken, aanpassen, patronen herkennen en waardevolle inzichten bieden om geïnformeerde beslissingen te nemen. Te gebruiken met NoSQL, SQL, GraphQL, Access en Excel`,
    size: {xs: 12, md: 6, xl: 4}, 
    backgroundColor: cardColor, 
    url: "/analyst"
  },
  {
    title: "AI Factuur Bouwer",
    description: `Gespecialiseerd in het genereren van facturen vanuit natuurlijke taal. 
                  Deze demo toont de kracht van het implementeren van een assistent in applicatie logica.`,
    size: {xs: 12, md: 6, xl: 12}, 
    url: "/invoice-builder",
    backgroundColor: cardColor, 
  },
  // {
  //   title: "Enquêteur",
  //   titleExtra: "Binnenkort beschikbaar",
  //   titleExtraColor: accentColor,
  //   disabled: true,
  //   description: `Binnenkort beschikbaar: onze AI Enquêteur, gespecialiseerd in het afnemen en analyseren van enquêtes. 
  //                 Deze assistent kan automatisch enquêtes opstellen, verzenden en de resultaten interpreteren om waardevolle feedback te verzamelen.`,
  //   size: {xs: 12, md: 6, xl: 6}, 
  //   backgroundColor: cardColor, 
  //   click: () => {}
  // },
  // {
  //   title: "Office Assistent",
  //   titleExtra: "Binnenkort beschikbaar",
  //   titleExtraColor: accentColor,
  //   disabled: true,
  //   description: `Maak binnenkort kennis met onze AI Office Assistent, een allround hulpmiddel voor kantoorwerkzaamheden. 
  //                 Deze assistent kan taken zoals agendabeheer, e-mailbeheer en documentbeheer automatiseren om de productiviteit te verhogen.`,
  //   size: {xs: 12, md: 6, xl: 4}, 
  //   backgroundColor: cardColor, 
  //   click: () => {}
  // },
  // {
  //   title: "AI Telefonist",
  //   titleExtra: "Binnenkort beschikbaar",
  //   titleExtraColor: accentColor,
  //   disabled: true,
  //   description: `Houd onze AI Telefonist in de gaten, een assistent die inkomende en uitgaande oproepen professioneel kan afhandelen. 
  //                 Deze AI kan klantenservice taken uitvoeren, afspraken inplannen en zorgen voor een efficiënte communicatie.`,
  //   size: {xs: 12, md: 6, xl: 8}, 
  //   backgroundColor: cardColor, 
  //   click: () => {}
  // },
];

const visionProducts : Array<AssistenData> = [
  {
    title: "AI Vision Assistent", 
    description: `Deze demo toont de kracht van vision in combinatie met natuurlijke taal.`,
    url: "/vision-assistant",
    backgroundColor: cardColor, 
    size: {xs: 12, md: 12, xl: 6}
  },
  {
    title: "Document Scanner",
    description: `Gespecialiseerde toepassing van onze vision assistent om belangrijke informatie uit documenten te lezen en om te zetten in gestructureerde tekst of natuurlijke taal.`,
    size: {xs: 12, md: 6, xl: 6}, 
    backgroundColor: cardColor, 
     url: "/document-reader",
  },
  {
    title: "Kenteken Scanner",
    description: `Gespecialiseerde toepassing van onze vision assistent om kentekeninformatie te scannen en te verwerken.`,
    size: {xs: 12, md: 6, xl: 12}, 
    backgroundColor: cardColor, 
    //click: () => {}
    url: "/carinfo",
  },
];