import { Constants } from "../constants";

export class GPTSettings {
    key: string;
    personality: string;
    description: string;
    company: string;
    strict: boolean;
    temperature: number;
    model?: string;

    constructor(key: string, description: string, personality: string, companyDescription: string = "", temperature: number = 0.5, strict: boolean = false){
        this.key = key;
        this.personality = personality;
        this.description = description;
        this.company = companyDescription;
        this.temperature = temperature;
        this.strict = strict;
    }

    setStrict(strict: boolean): GPTSettings{
        this.strict = strict;
        return this;
    }

    setTemperature(temperature: number): GPTSettings{
        this.temperature = temperature;
        return this;
    }

    setModel(model: string): GPTSettings{
        this.model = model;
        return this;
    }
}