import { Component } from "react";
import { BaseChat } from "./base-chat";
import { AssistantDataStore, CompanyDataStore, storeSubscription } from "../../data/data-store";
import { Tool } from "../../data/models/tool";
import { InvoiceData, validateInvoiceData } from "../../data/models/invoice-data";
import { Box, IconButton, Modal, TextField } from "@mui/material";
import { Invoice } from "../components/templates/invoice";
import { overlayColor, styles, theme } from "../styles/main-styles";
import CloseIcon from '@mui/icons-material/Close';
import { SettingsToolbar } from "../components/settings-toolbar";
import { SettingsNotice } from "../components/common/settings-notice";

interface InputFieldData{
    placeholder: string;
    title: string;
    value: string;
    onChange: (val: string) => void;
}
interface State {
    invoiceData: InvoiceData;
    showModal: boolean;
}

class InvoiceBuilder extends Component {

    public state: State = {
        invoiceData: {
          date: "",
          due_date: "",
          customer_name: "",
          customer_address: "",
          customer_postal_code: "",
          customer_city: "",
          customer_country: "",
          customer_vat_number: "",
          company_name: "",
          company_address: "",
          company_postal_code: "",
          company_city: "",
          company_country: "",
          company_vat_number: "",
          product_lines: [
            {
              product_description: "",
              product_quantity: 0,
              product_price: "",
              product_total: ""
            }
          ],
          subtotal: "",
          vat_percentage: "",
          vat_amount: "",
          total_amount: "",
          payment_term: "",
          bank_account_number: "",
          payment_reference: "",
          notes: "",
          terms_and_conditions: ""
        },
        showModal: false,
      };

    public render() {
        return (
            <BaseChat
                mobileMenu={true}
                store={AssistantDataStore} 
                mainContentainerExtra={this.main()}
                temperature={0}
                chatTitle="Factuur Bouwer"
                chatTitleMobile="Factuur Bouwer"
                systemMessages={[this.buildSystemMessage()]}
                actionContainer={this.renderCompanyInfo()}
                canDrawerOpen={true}
                drawerOpenOnStart={window.innerWidth < theme.breakpoints.values.xl}
                defaultMessageContent={"Hallo, ik ben een factuur specialist en kan je helpen met het maken van facturen.<br><br>Laten we beginnen met het maken van een factuur!"}
                inputPlaceholder={"Wat wil je dat er gegenereerd wordt?"}
                tools={[new Tool("create_invoice", this.tool() ,args=>{
                    if(!args.data) return;
                    // if(!validateInvoiceData(args.data)) {
                    //   console.log("INVALID Invoice");
                    //   return;
                    // }
                      try {
                        this.setState({invoiceData: args.data, showModal:true});
                    } catch (e) {
                        console.log(e);
                    }
                })]}
            />
        );
    }

    public main(){
        const sx = {boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.2);",outline: "none", border:"none", "&:focus": {outline: "none", border:"none"}};
        return (
            <Modal open={this.state.showModal} sx={sx}>
                <Box sx={{position:"absolute", left:"50%", top:"50%", transform:"translate(-50%,-50%)", maxHeight:"100vh", maxWidth:"100vw" }}>
                    <Box sx={{backgroundColor:overlayColor}}> 
                        <IconButton onClick={()=>this.setState({showModal:false})}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{ maxHeight: "80vh",overflowY:"auto"}}>
                        <Invoice invoiceData={this.state.invoiceData}/>
                    </Box>
                </Box>
            </Modal>
        )
    }

    
    private renderCompanyInfo(){
        const state = CompanyDataStore.getState();
        const inputFields: Array<InputFieldData> = [
          {
            placeholder: "",
            title: "Bedrijfsnaam",
            value: state.companyName,
            onChange: (val: string) => {
                state.setCompanyName(val);
            }
          },
          {
            placeholder: "",
            title: "Adres",
            value: state.companyAddress,
            onChange: (val: string) => {
              state.setCompanyAddress(val);
            }
          },
          {
            placeholder: "",
            title: "Postcode",
            value: state.companyPostalCode,
            onChange: (val: string) => {
                state.setCompanyPostalCode(val);
            }
          },
          {
            placeholder: "",
            title: "Plaats",
            value: state.companyCity,
            onChange: (val: string) => {
                state.setCompanyCity(val);
            }
          },
          {
            placeholder: "",
            title: "Land",
            value: state.companyCountry,
            onChange: (val: string) => {
                state.setCompanyCountry(val);
            }
          },
          {
            placeholder: "",
            title: "BTW nummer",
            value: state.companyVatNumber,
            onChange: (val: string) => {
                state.setCompanyVatNumber(val);
            }
          },
        ]
        return ( 
          <div style={styles.mainPage.leftContainer.container}>
                <SettingsToolbar/>
                <Box sx={{pl:2, pr:2, minWidth:{
                    xs:200,
                    md:400,
                    xl:600
                }}}>
                    <h3 style={{marginTop:24}}>Vul je bedrijfsgegevens in</h3>
                    <h5 style={{marginTop:0,marginBottom:32}}>Niet nodig, maar wel handig!</h5>
                    { inputFields.map((field, index)=>this.input(index, field)) }
                </Box>
                <Box sx={{ display:"flex", flexDirection:"column", alignItems:"start", ml:2}}>
                    <SettingsNotice/>
                </Box>
          </div>
        )
    }

    private input(index: number,field: InputFieldData){
        const textFieldStyle = { 
          width: "100%",
        }
        const boxStyle = {
          width: "100%",
          mt:1,
          mb:1
        }
        return (
          <Box sx={boxStyle} key={index}>
              <p>{field.title}</p>           
              <TextField
                  sx={textFieldStyle}
                  variant="outlined"
                  value={field.value}
                  placeholder={field.placeholder}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event.target.value);
                  }}
              />
          </Box>
        );
    }

    private buildSystemMessage(){
        return `Don't make assumptions about what values to plug into functions. Ask for clarification if a user request is ambiguous. Always answer in Dutch. Today's date is ${new Date()}. The company info available is: ${this.buildCompanyInfo()}` //`Je bent een factuur bouwer. Je zorgt dat je al deze velden ${invoiceFields.join(", ")} aan de gebruiker bevraagd en invult.`;
    }

    private buildCompanyInfo(){
        const state = CompanyDataStore.getState();
        const isEmpty = (val: string)=>val.trim().length<1;

        const knownInfo = [
            {
                key:"company name",
                value: state.companyName,
            },
            {
                key:"company address",
                value: state.companyAddress,  
            },
            {
                key:"company postal_code",
                value: state.companyPostalCode,
            },
            { 
                key:"company city",
                value: state.companyCity,
            },{
                key:"company country",
                value: state.companyCountry,
            },
            {
                key:"company vat_number",
                value: state.companyVatNumber,
            }
        ];

        return knownInfo.map((info, index)=>{
            const lastMember = index===knownInfo.length-1;
            if(isEmpty(info.value)) return "";
            return `${info.key}: ${info.value}${lastMember? "." : ", "}`;
        });
    }

    private tool() {
        return {
          "type": "function",
          "function": {
            "name": "create_invoice",
            "description": "Creates an invoice in the Dutch language, structured as JSON.", //" Asks for all the fields that are required for an invoice and after that creates an invoice in JSON.",
            "parameters": {
              "type": "object",
              "properties": {
                "data": {
                  "type": "object",
                  "properties": {
                    "date": {
                      "type": "string",
                      "description": "The invoice date"
                    },
                    "due_date": {
                      "type": "string",
                      "description": "The due date of the invoice"
                    },
                    "customer_name": {
                      "type": "string",
                      "description": "The name of the customer"
                    },
                    "customer_address": {
                      "type": "string",
                      "description": "The address of the customer"
                    },
                    "customer_postal_code": {
                      "type": "string",
                      "description": "The postal code of the customer"
                    },
                    "customer_city": {
                      "type": "string",
                      "description": "The city of the customer"
                    },
                    "customer_country": {
                      "type": "string",
                      "description": "The country of the customer"
                    },
                    "customer_vat_number": {
                      "type": "string",
                      "description": "The VAT number of the customer"
                    },
                    "company_name": {
                      "type": "string",
                      "description": "The name of the company"
                    },
                    "company_address": {
                      "type": "string",
                      "description": "The address of the company"
                    },
                    "company_postal_code": {
                      "type": "string",
                      "description": "The postal code of the company"
                    },
                    "company_city": {
                      "type": "string",
                      "description": "The city of the company"
                    },
                    "company_country": {
                      "type": "string",
                      "description": "The country of the company"
                    },
                    "company_vat_number": {
                      "type": "string",
                      "description": "The VAT number of the company"
                    },
                    "product_lines": {
                      "type": "array",
                      "description": "The product lines of the invoice",
                      "items": {
                        "type": "object",
                        "properties": {
                          "product_description": {
                            "type": "string",
                            "description": "The description of the product"
                          },
                          "product_quantity": {
                            "type": "number",
                            "description": "The quantity of the product"
                          },
                          "product_price": {
                            "type": "string",
                            "description": "The price of the product"
                          },
                          "product_total": {
                            "type": "string",
                            "description": "The total price of the product"
                          }
                        }
                      }
                    },
                    "subtotal": {
                      "type": "string",
                      "description": "The subtotal amount of the invoice"
                    },
                    "vat_percentage": {
                      "type": "string",
                      "description": "The VAT percentage applied to the invoice"
                    },
                    "vat_amount": {
                      "type": "string",
                      "description": "The VAT amount of the invoice"
                    },
                    "total_amount": {
                      "type": "string",
                      "description": "The total amount of the invoice"
                    },
                    "payment_term": {
                      "type": "string",
                      "description": "The payment term of the invoice"
                    },
                    "bank_account_number": {
                      "type": "string",
                      "description": "The bank account number for payment"
                    },
                    "payment_reference": {
                      "type": "string",
                      "description": "The payment reference for the invoice"
                    },
                    "notes": {
                      "type": "string",
                      "description": "Additional notes for the invoice"
                    },
                    "terms_and_conditions": {
                      "type": "string",
                      "description": "The terms and conditions of the invoice"
                    }
                  }
                },
                "reply": {
                  "type": "string",
                  "description": "What you want to say to the user"
                }
              },
              "required": ["data.date", "data.due_date", "data.customer_name", "data.customer_address", "data.customer_postal_code", "data.customer_city", "data.customer_country", "data.customer_vat_number", "data.company_name", "data.company_address", "data.company_postal_code", "data.company_city", "data.company_country", "data.company_vat_number", "data.product_lines", "data.subtotal", "data.vat_percentage", "data.vat_amount", "data.total_amount", "data.payment_term", "data.bank_account_number", "data.payment_reference", "data.notes", "data.terms_and_conditions", "reply"]
            }
          }
        }
    }
}

export const InvoiceBuilderAssistant = storeSubscription(InvoiceBuilder,CompanyDataStore);