import { Component, createRef, RefObject } from "react";
import { AppToolbar } from "../components/app-toolbar";
import { Box, Button, CircularProgress, Snackbar, Typography } from "@mui/material";
import React from "react";
import imageCompression from 'browser-image-compression';
import { accentColor, containerColor, styles } from "../styles/main-styles";
import { MessageServiceInstance } from "../../domain/services/message.service";
import  {File} from "../../data/models/file";
import { Role, RoleType } from "../../data/models/gpt-message";
import { Tool } from "../../data/models/tool";
import { Link } from "framer-motion";
import { ServiceIndicator } from "../components/service-indicator";

interface CarInfoState {
    imageData: File;
    loading: boolean;
    stateInfo: string;
    carInfo: string;

    snackBarOpen: boolean;
    snackBarMessage: string;

    data: {
        kenteken: string;
        voertuigsoort: string;
        merk: string;
        handelsbenaming: string;
        bouwjaar: string;
    } | null;
}

export class CarInfo extends Component {

    private systemMessages = [`Je bent een voertuig scanner. Je reageert alleen in het Nederlands.`];
    private placeholderImage: string = "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"; 
    private inputRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    public state: CarInfoState = {
        imageData: File.empty(),
        loading: false,
        stateInfo:"",
        carInfo:"",
        data: null,
        snackBarOpen: false,
        snackBarMessage:"",
    }

    private dimenSmall = "60vw"

    private contentContainertStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        pt: 5,
        flex:1,
        flexGrow: 1,
    }

    private imageContainerStyle = {
        height: { xs: this.dimenSmall, md: 400 }, width: { xs: this.dimenSmall, md: 400 },
        backgroundColor: containerColor,
        borderRadius: "1em"
    }
    private imageStyle = {
        height: { xs: this.dimenSmall, md: 400 }, width: { xs: this.dimenSmall, md: 400 }, //TODO make responsive
        objectFit: "cover",
        borderRadius: "1em"
    };

    private loaderContainerStyle = {
        mt:8
        // display: this.state.loading ? "inherit" : "none",
        // height:"100%", width:"100%",
        // left:0, top:0,
        // position:"absolute", 
    }
    private loaderStyle = {
        // position:"absolute", 
        // top:"50%", left:"50%", 
        // transform:"translate(-50%,-50%)",
    }
    public render(){
        const showCarInfo = (this.state.carInfo.length>0 && this.state.stateInfo.length>0 || this.state.data) && !this.state.loading;
        return (
            <Box sx={{height:"100%", width:"100%", position:"absolute", display:"flex", flexDirection:"column"}}>
                <AppToolbar title={"AI Kenteken Scanner"} mobileTitle={"AI Kenteken Scanner"} logo={true} singplePageLayout={true} onMenuClick={()=>{}}/>
                <Box sx={this.contentContainertStyle}>

                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",width:{xs: "100%", md:600  }, pl:4,pr:4, boxSizing:"border-box"}}>    
                        {this.renderImage()}
                        
                        {!showCarInfo && this.renderDescription()}
                        {showCarInfo && this.renderInfo()}
                        {this.state.loading && this.renderLoader()}
                    </Box>

                    <Box sx={{pb:4}}>
                        {this.renderInputBox()}
                    </Box>

                </Box>
                <Snackbar
                    open={this.state.snackBarOpen}
                    autoHideDuration={6000}
                    onClose={()=>this.setState({snackBarOpen:false})}
                    message={this.state.snackBarMessage}
                    ContentProps={styles.mainPage.snackbar}
                />
                <ServiceIndicator />
            </Box>
        )
    }

    private renderDescription(){
        return (
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",boxSizing:"border-box"}}>
                <Typography variant="body1" sx={{mt:2}}>Klik op de onderstaande knop om een afbeelding van een auto met zichtbaar kenteken te uploaden.</Typography>
            </Box> 
        )
    }

    private renderInfo(){
        const data = this.state.data;
        return (
            <Box>
                {data && this.renderRDWData(data)}
                {this.state.stateInfo!=="" && <Typography variant="body1" sx={{mt:data?0:2}}><b>Staat van auto:</b><br/>{this.state.stateInfo}</Typography>}
                {this.state.carInfo!=="" && <Typography variant="body1" sx={{mt:2}}><b>Opmerking AI:</b><br/>{this.state.carInfo}</Typography>}
            </Box>
        )
    }

    private renderRDWData(data: any){
        return (
            <>
                <Typography variant="body1" sx={{mt:2}}><b>Kenteken:</b> {data?.kenteken} </Typography>
                <Typography variant="body1" sx={{mt:0}}><b>Voertuigsoort:</b> {data?.voertuigsoort} </Typography>
                <Typography variant="body1" sx={{mt:0}}><b>Merk:</b> {data?.merk} </Typography>
                <Typography variant="body1" sx={{mt:0}}><b>Handelsbenaming:</b> {data?.handelsbenaming} </Typography>
                <Typography variant="body1" sx={{mt:0,}}><b>Bouwjaar:</b> {data?.bouwjaar} </Typography>
                <Typography variant="body1" sx={{mt:1,mb:2}}><a href={`https://ovi.rdw.nl/default.aspx?kenteken=${data?.kenteken}`} target="_blank" rel="noreferrer" color="primary">Meer informatie</a></Typography>
            </>
        )
    }

    private renderImage(){
        return (
            <Box sx={this.imageContainerStyle}>
                <Box component={"img"} src={this.state.imageData.data.length>0? this.state.imageData.data : this.placeholderImage} alt="" sx={this.imageStyle}/>
            </Box>
        );
    }

    private renderLoader(){
        return (
            <Box sx={this.loaderContainerStyle}>
                <CircularProgress sx={this.loaderStyle} />
            </Box>
        );
    }

    private renderInputBox(){
        return (
            <>
                <input type="file" style={{display:"none"}} ref={this.inputRef} accept="image/*" onChange={(event)=>this.handleFileChange(event)}/>
                <Button 
                    disabled={this.state.loading}
                    sx={{...styles.mainPage.leftContainer.saveButton, width:200, mt:4}} size="large" disableElevation variant="contained"
                    onClick={()=>this.inputRef.current?.click()}>
                        Uploaden
                </Button>
            </>
        );
    }

    private async handleFileChange(event: React.ChangeEvent<HTMLInputElement>){ //todo if(!file) return;
        this.setState({loading:true, data:null, info:""});

        const file = event.target.files?.[0];
        if (file) {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1, //TODO make global setting
                maxWidthOrHeight: 800,
                useWebWorker: true
            });
            const reader = new FileReader();
            reader.onloadend = async () => {
                try{
                    const image = new File(file.name as string, reader.result as string);
                    this.setState({imageData: image});
     
                    const aiResult = await this.doAIRequest(image);

                     if(aiResult.tools.length===0) {
                         this.setState({loading:false, snackBarOpen:true, snackBarMessage:"Kenteken niet gevonden op foto"});
                         return;
                         //TODO notify user
                     }
     
                    const toolData = JSON.parse(aiResult.tools[0].function.arguments);
                    const licensePlate = toolData.data;
                    const carData = await this.getCarData(this.formatLicensePlate(licensePlate));
                    
                    if(carData.length===0) {
                        if(licensePlate.length>0) {
                            this.setState({
                                loading:false, 
                                snackBarOpen:true, 
                                snackBarMessage:"Auto niet gevonden bij RDW!",                         
                                stateInfo: toolData.state || "", 
                                carInfo: toolData.unique || ""
                            });
                        }
                        this.setState({loading:false, snackBarOpen:true, snackBarMessage:"Auto niet gevonden bij RDW!"});
                        return;
                     }
     
                    const mainResult = carData[0];
                    mainResult.bouwjaar = this.formatDate(mainResult.datum_eerste_toelating);
                    this.setState({
                        loading:false,
                        data: {...mainResult},
                        stateInfo: toolData.state || "", 
                        carInfo: toolData.unique || "",
                    });
                } catch(e){
                    console.log(e);
                    this.setState({loading:false, snackbarOpen:true, snackbarMessage: "Er is een onbekende fout opgetreden"});
                }
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    private async doAIRequest(image: File){
        MessageServiceInstance.setupStacked(this.systemMessages,0,[new Tool("get_car_info", this.tool() ,args=>{})]);
        const request = await MessageServiceInstance.GTPPost("", image, Role.USER);
        return await request.json() as any;
    }

    private async getCarData(licensePlate: string){
        console.log(licensePlate);
        const response = await fetch(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${licensePlate}`,{
            headers:{
                'X-App-Token': 'PLyMhQ5PVvrfpmX4VPQ4SS1Is' 
            }
        });

        if(!response.ok)console.log("Error fetching car data");
        return await response.json();
    }

    private formatLicensePlate(licensePlate: string): string {
        let formattedPlate = licensePlate.replace(/-/g, '');
        formattedPlate = formattedPlate.toUpperCase();
        return formattedPlate;
    }

    private tool() {
        return {
          "type": "function",
          "function": {
            "name": "get_car_info",
            "description": "Get the licence plate number as text from the image", 
            "parameters": {
              "type": "object",
              "properties": {
                "data": {
                  "type": "string",
                  "description": "The license plate of the car"
                },
                "reply": {
                  "type": "string",
                  "description": "What you want to say to the user"
                },
                "state": {
                  "type": "string",
                  "description": "Tell something about the state of the car" 
                },
                "unique" :{
                    "type": "string",
                    "description": "Give a description of the car"
                }
              },
              "required": ["data", "reply", "state", "unique"]
            }
          }
        }
    }

    private formatDate(input: string): string {
        // Controleer of de invoer correct is
        if (input.length !== 8 || isNaN(Number(input))) {
          return input;
        }
      
        // Haal jaar, maand en dag uit de input string
        const year = input.substring(0, 4);
        const month = input.substring(4, 6);
        const day = input.substring(6, 8);
      
        // Combineer in het formaat DD-MM-YYYY
        return `${day}-${month}-${year}`;
      }
}