import React, { Component } from "react";
import {
  Toolbar,
  Typography,
} from "@mui/material";
import {
  styles,
} from "../styles/main-styles";
import { navigate } from "../router-config";

interface Props {
  children?: React.ReactNode;
}

export class SettingsToolbar extends Component<Props> {

    public render() {
      return (
          <Toolbar style={styles.settingsToolbar.toolbar}>
              <img src="pa-logo.png" onClick={()=>navigate("/")} alt=""style={styles.settingsToolbar.image}/>
              <p>Pixel Ape</p>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
              {this.props.children}
          </Toolbar>
        );
    }
}
