import { Button } from "@mui/material";
import { accentColor } from "../../styles/main-styles";
import React from "react";
import { ContactDialog } from "../contact-dialog";

export function ContactButton({click}:{click?:()=>void}): JSX.Element {
    const [showContactModal, setShowContactModal] = React.useState(false);
    const btnStyle = {
        borderRadius: 12,
        backgroundColor: "rgba(88, 196, 220,0.2)",
        textTransform: "none",
        pl:2,pr:2,
        color: accentColor
    };

    return (
        <>
            <Button sx={btnStyle} color="inherit" onClick={()=>click? click():setShowContactModal(true)}>Contact</Button>
            <ContactDialog onClose={()=>setShowContactModal(false)} open={showContactModal}/>
        </>
    );
}