import { MainContainer, Message, MessageImageContent, MessageList, MessageModel, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { Component } from "react";
import { AI_NAME, Constants } from "../../data/constants";
import { MessageServiceInstance } from "../../domain/services/message.service";
import { Box, Drawer, IconButton, Snackbar, Typography } from "@mui/material";
import {  accentColor, containerColor, styles } from "../styles/main-styles";
import { AppToolbar } from "../components/app-toolbar";
import { ChatInput } from "../components/chat/chat-input";
import React from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { StoreApi } from "zustand";
import { Tool } from "../../data/models/tool";
import { File } from "../../data/models/file";
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import zIndex from "@mui/material/styles/zIndex";
import { ImageMessageDOM } from "../components/templates/image-message";
import { ServiceIndicator } from "../components/service-indicator";
import { GlobalDataStore, storeSubscription } from "../../data/data-store";
import { Global } from "@emotion/react";

interface State {
    messages: MessageModel[]; 
    isTyping: boolean;
    input: string;
    drawerOpen: boolean;
    showSnackbar: boolean;
    scrollToBottom: boolean;
    scrollToBottomButton: boolean;

    imageData: File;
}

interface Props<T> {
    chatTitle:string;
    logo?: boolean;
    chatTitleMobile:string;
    actionContainer: React.ReactNode;
    mainContentainerExtra?: React.ReactNode;
    defaultMessageContent: string;

    inputPlaceholder?: string; 
    inputOnChange?: (val:string)=>void;
    inputOnSubmit?: ()=>void;
    attachmentEnabled?: boolean;
    attachmentOnSubmit?: (file: File)=>void;

    onDrawerClose?: ()=>void;
    canDrawerOpen?: boolean;
    canDrawerClose?: boolean;
    drawerOpenOnStart?: boolean; 

    singlePage?: boolean;
    mobileMenu?: boolean;

    store: StoreApi<T>; //TODO should be optional
    systemMessages: Array<string>;
    temperature: number;

    tools?: Array<Tool>;
    model?: string;
    tokenLimit?: number;
}

export class BaseChatComponent<T> extends Component<Props<T>, State> { 

    private messageServiceListenerIndex: number | undefined;

    static defaultProps = {
        mobileMenu: true,
        logo: false,
        singlePage: false,
        canDrawerOpen: true,
        canDrawerClose: true,
        tools: [],
        tokenLimit: Infinity,

        attachmentEnabled: false,
    }

    public state = {
        messages: [] as MessageModel[],
        isTyping: true,
        input: "",
        drawerOpen: false,
        showSnackbar: false,
        scrollToBottom: false,
        scrollToBottomButton: false,
        imageData: File.empty(),
    }

    public render(){ //TODO modal overwegen
        return (
            <div style={styles.mainPage.pageContainer}>
                <div style={{...styles.mainPage.contentContainer}}>
                    <Box sx={{ display: { xl: 'inherit', xs: 'none' } }}>{this.renderLeftContentContainer()}</Box>
                    {this.renderRightContentContainer()}                  
                </div>
                {this.renderDrawer()}
                <ServiceIndicator />
                {this.props.mainContentainerExtra}
            </div>
        )
    }

    private renderLeftContentContainer(){
        return ( 
            <div style={styles.mainPage.leftContainer.container}>
                {this.props.actionContainer}
            </div>
        )
    }


    private renderRightContentContainer(){ //TODO clean up this.props.attachmentOnSubmit?.(file)
        const {inputPlaceholder, inputOnChange, inputOnSubmit} = this.props;
        return (
            <div style={styles.mainPage.rightContainer.container}>
                <AppToolbar
                    logo={this.props.logo}
                    mobileMenu={this.props.mobileMenu}
                    singplePageLayout={this.props.singlePage}
                    onMenuClick={()=>this.setState({drawerOpen:true})}
                    title={this.props.chatTitle}
                    mobileTitle={this.props.chatTitleMobile}
                /> 
                {this.renderChatContainer()}
                {this.renderAttachmentBar()}
                <ChatInput 
                    attachmentEnabled={this.props.attachmentEnabled}
                    onAttachmentSubmit={(file)=>{
                        this.setState({imageData: file});
                        this.props.attachmentOnSubmit?.(file);
                    }}
                    value={this.state.input}
                    onChange={(val:string)=> {
                        this.setState({input:val})
                        inputOnChange?.(val);
                    }}
                    placeholder={inputPlaceholder || ""}
                    onSubmit={()=>{
                        this.handleButtonInput();
                        inputOnSubmit?.();
                    }}
                    canType={!this.state.isTyping && GlobalDataStore.getState().serverRunning}
                />
                {/* <DisclaimerText/> */}<Box sx={{height:{ xs:24, md:36}}}/>
            </div>
        )
    }

    private renderAttachmentBar(){
        const parentStyle = {
            display: File.hasValue(this.state.imageData)? "flex" : "none",
            //display:"flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            // height:32,
            width:"100%",
        }
        const containerStyle = {
            display: "flex",
            width: {
                xs:"90%",
                md:"800px",
            }
        }
        const childStyle = {
            height:8,
            width:120,
            p:2,
            mb:{
                xs:2,   
                md:0,
                xl:-2,
                xxl:-2,
            },
            borderRadius:"2em",
            backgroundColor:accentColor,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
        }
        const textStyle = {
            marginRight:2,
            whiteSpace:"nowrap",
            overflow:"hidden",
            textOverflow:"ellipsis",
            flexGrow:1,
            fontSize:14,
        }
        return (
            <Box sx={parentStyle}>
                <Box sx={containerStyle}>
                    <Box sx={childStyle}> 
                        <ImageIcon sx={{height:12}}/>
                        {/* <Typography variant="body2" component="div" sx={textStyle}>HAllo-ewtwefg.png</Typography> */}
                        {/* <Typography variant="body1" component="div" sx={textStyle}>{this.state.imageData.name}</Typography> */}
                        <p style={textStyle}>{this.state.imageData.name}</p>
                        <IconButton onClick={()=>this.setState({imageData: File.empty()})}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    private renderDrawer(){ 
        return (
            <Drawer
                anchor="left"                
                open={this.props.canDrawerOpen? this.state.drawerOpen : false}
                onClose={()=>{
                    this.props.onDrawerClose?.();                
                    if(this.props.canDrawerClose) this.setState({drawerOpen:false});
                }}
                variant="temporary"
                sx={styles.mainPage.drawer}>
                {this.renderLeftContentContainer()}
            </Drawer>
        )
    }

    private renderSnackbar(){
        return (
            <Snackbar
                open={this.state.showSnackbar}
                onClose={()=>this.setState({showSnackbar:false})}
                message="Veranderingen zijn opgeslagen."
                ContentProps={styles.mainPage.snackbar}
                autoHideDuration={1200}
            />
        )
    }

    private renderChatContainer(){ 
        return(
            <MainContainer style={styles.mainPage.rightContainer.chatContainer}>
                <MessageList
                    ref={this.messageListRef}
                    autoScrollToBottom={this.state.scrollToBottom}
                    typingIndicator={this.state.isTyping? <TypingIndicator content={AI_NAME+" is aan het typen"} /> : null}>
                    {this.state.messages.map((m: any,i) => {
                        if(+m.sender === Constants.CHATTER.SYSTEM) return null;
                        if(m.message === "") return null;
                        return <Message key={i} model={m}/>
                    })}
                </MessageList>
                {this.scrollToBottomButton()}
                {/* {this.renderAttachmentBar()} */}
            </MainContainer>
        )
    }

    private scrollToBottomButton(){
        const dimension = { xs: 36, md: 48}
        return (
            <IconButton onClick={()=>this.scrollToBottom()} sx={{
                position: "absolute",
                left: "50%",
                height: this.state.scrollToBottomButton? dimension : 0,
                width: this.state.scrollToBottomButton? dimension : 0,
                visibility: this.state.scrollToBottomButton? "inherit" : "hidden",
                opacity: this.state.scrollToBottomButton? 1 : 0,
                transition: "all 0.2s ease-in-out",
                bottom: 0,
                borderRadius: "2em",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(35,39,47,1)",
                color: "white",
                "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.3)",
                    color: "white",
                }
            }}>
                <ArrowDownwardIcon/>
            </IconButton>
        );
    }

    private messageListRef = React.createRef<HTMLDivElement>();
    private scrollToBottom()  {    
        //@ts-ignore
        this.messageListRef.current?.scrollToBottom( 'auto' );
    }

    private attachScrollListener(){// TODO remove listener
        const classTargets = document?.getElementsByClassName('scrollbar-container');
        if(classTargets.length<1) return;

        const list = classTargets[0];
        const action = ()=>{
            const isScrollAtBottom = (Math.ceil(list.scrollTop) + list.clientHeight >= list.scrollHeight) 
            this.setState({scrollToBottomButton: !isScrollAtBottom});
        };
        list.addEventListener('scroll', event=>{
            action();
        });
        action();
    }
    
    private handleButtonInput(){
        if(this.state.input.trim().length<1 || this.state.isTyping) return;
        this.sendMessage();
    }

    private buildUIMessageModel(message:string, id:number, imgData:string = ""): MessageModel{ //TODO embed image
        const outgoing = id === Constants.CHATTER.USER;
        return {
            position:"normal",
            direction: outgoing? "outgoing" : "incoming",
            message: imgData.length>0? ImageMessageDOM(message, imgData) : message,
            sentTime: "just now",
            sender: outgoing? "User" : AI_NAME,
        }
    }

    private addMessageToUI(message:string, id:number, imgData:string = ""){
        this.state.messages.push(this.buildUIMessageModel(message,id, imgData));
        this.setState({input: "", isTyping: id === Constants.CHATTER.USER}); //TODO
    }

    private async sendMessage(){
        const attachment = this.props.attachmentEnabled && File.hasValue(this.state.imageData)? this.state.imageData : File.empty(); 
        const messageContent = this.state.input;

        this.addMessageToUI(messageContent, Constants.CHATTER.USER,attachment.data) //TODO move to onMessageUpdate
        this.setState({input: "", isTyping: true, imageData: File.empty()}); //TODO statefull maken in message service

        if(!GlobalDataStore.getState().serverRunning) return; //TODO show toast?
        await MessageServiceInstance.queryAI(messageContent, attachment);
    }

    private buildDefault(): MessageModel{
        return this.buildUIMessageModel(this.props.defaultMessageContent, Constants.CHATTER.AI);
    }

    public componentDidMount(): void {
        if(this.props.drawerOpenOnStart) this.setState({drawerOpen:true});

        this.messageServiceListenerIndex = MessageServiceInstance.addListener(this);
        
        this.setup();
        this.addMessageToUI(this.props.defaultMessageContent, Constants.CHATTER.AI);
        
        setTimeout(()=>{
            this.setState({scrollToBottom: true});
            this.attachScrollListener();
        },100); //TODO fucking hack maja..

        //this.checkServerStatus();  //TODO global?
    }

     public componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State>, snapshot?: any): void { //TODO add systemmessages array check
        const checkArray = (a:Array<string>, b:Array<string>) => JSON.stringify(a) === JSON.stringify(b);
        if(
            !checkArray(prevProps.systemMessages,this.props.systemMessages) || 
            prevProps.temperature !== this.props.temperature
        ) {
            this.clear();
            this.setup(); //TODO broke it
        }
    }

    public componentWillUnmount(): void {
        MessageServiceInstance.removeListener(this.messageServiceListenerIndex!);
    }

    private clear(){
        this.setState({messages: [this.buildDefault()], scrollToBottom: false});
        setTimeout(()=>{this.setState({scrollToBottom: true})},100);
    }
    
    private setup(){
        MessageServiceInstance.setupStacked(
            this.props.systemMessages, 
            this.props.temperature, 
            this.props.tools, 
            this.props.model? this.props.model :  Constants.DEFAULT_AI_SETTINGS.model
        );
    }

    public onMessagesUpdate(messages: Array<MessageModel>) { //TODO update based on store? 
        const chat = [];
        chat.push(this.buildDefault()) //TODO wacht... is dit handig?

        messages.forEach(message=>chat.push(message));

        this.setState({isTyping:false, messages:chat});
    }

}
export const BaseChat = storeSubscription(BaseChatComponent,GlobalDataStore);