import React from "react";
import { Constants, DEFAULT_ASSISTANT_SETTINGS } from "../../../data/constants";
import { AssistantDataStore } from "../../../data/data-store";
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { accentColor } from "../../styles/main-styles";
import { navigate } from "../../router-config";

export function SuggestionMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const applySettings = (key: string)=>{
        const data = DEFAULT_ASSISTANT_SETTINGS.find(val=>val.key === key);
        if(data != null){
            const state = AssistantDataStore.getState();
            state.setDescriptionSetting(data.description);
            state.setPersonalitySetting(data.personality);
            state.setCompanyDescriptionSetting(data.company);
            state.setTemperature(data.temperature);
            state.setModel(data.model? data.model : Constants.DEFAULT_AI_SETTINGS.model);

            if(data.strict != null){
                state.setStrictMode(data.strict);
            }
        } 
         handleClose();
    }
    return (
        <div>
            <Button
                variant="outlined" 
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                    borderRadius: 12,
                    textTransform: "none",
                    color: accentColor
                }}
                onClick={handleClick}>Suggesties</Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{"aria-labelledby": "basic-button"}}>
                    <MenuItem onClick={()=>applySettings("blanco")}>Blanco</MenuItem>
                    <MenuItem onClick={()=>applySettings("default")}>Allround Assistent Pietertje</MenuItem>
                    <Divider />
                    <MenuItem onClick={()=>applySettings("financial_advisor")}>Junior Accountant</MenuItem>
                    <MenuItem onClick={()=>applySettings("customer_support_webshop")}>Klantenservice Webshop</MenuItem>
                    <MenuItem onClick={()=>applySettings("social_media_manager")}>Social Media Manager</MenuItem>
                    <MenuItem onClick={()=>applySettings("school_teacher")}>Basisschool Docent</MenuItem>
                    <Divider />
                    {/* <MenuItem onClick={()=>applySettings("invoice_maker")}>Factuur bouwer</MenuItem> */}
                    <MenuItem onClick={()=>applySettings("translator")}>Vertaler</MenuItem>
                    <Divider />
                    <MenuItem onClick={()=>navigate("/analyst")}>Data Analist</MenuItem>
                    <MenuItem onClick={()=>navigate("/invoice-builder")}>Factuur Bouwer</MenuItem>
                    <MenuItem onClick={()=>navigate("/webdev")}>Webdeveloper (Experimenteel)</MenuItem>
                    {/* <MenuItem onClick={()=>applySettings("artist")}>ASCII kunstenaar</MenuItem> */}
                    <Divider />
                    {/* <MenuItem onClick={()=>applySettings("customer_support_groothandel_bloembollen")}>Klantenservice Groothandel Bloembollen </MenuItem> */}
                {/* <MenuItem onClick={()=>applySettings("psychology_support")}>Praktijk Ondersteuner Psychologie</MenuItem> */}
                {/* <MenuItem onClick={()=>applySettings("customer_support_webshop")}>Junior Accountant</MenuItem> */}
                
                {/* <MenuItem onClick={()=>applySettings("youth_counselor")}>Jeugdzorg Medewerker</MenuItem> */}
            </Menu>
        </div>
    );
}
