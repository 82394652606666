import {Box, Slider, Switch, TextField, Typography } from "@mui/material";
import { Component } from "react";
import { extraDarkColor, styles} from "../styles/main-styles";

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { SettingsToolbar } from "../components/settings-toolbar";
import { DefaultMessageDOM } from "../components/templates/default-message";
import { AssistantDataStore, storeSubscription } from "../../data/data-store";
import { AI_NAME, Constants } from "../../data/constants";
import { HelpMenu } from "../components/menus/help-menu";
import React from "react";
import { SuggestionMenu } from "../components/menus/suggestion-menu";
import { BaseChat } from "./base-chat";
import { SettingsNotice } from "../components/common/settings-notice";

// interface State {
//     messages: MessageModel[]; 
//     isTyping: boolean;
//     input: string;
//     drawerOpen: boolean;
//     showSnackbar: boolean;
//     scrollToBottom: boolean;
//     scrollToBottomButton: boolean;
// }

// const AI_PLACEHOLDER = DefaultMessageDOM(AI_NAME);

//TODO move literals to constants
//TODO restrict sending if waiting for response
//TODO add token limit
//TODO Put it all together and double check message flow
// export class MainComponent extends Component<{},State> implements MessageServiceListener {

//     public state = {
//         messages: [] as MessageModel[],
//         isTyping: true,
//         input: "",
//         drawerOpen: false,
//         showSnackbar: false,
//         scrollToBottom: false,
//         scrollToBottomButton: true
//     }

//     public render(){
//         return (
//             <div style={styles.mainPage.pageContainer}>
//                 <div style={{...styles.mainPage.contentContainer}}>
//                     <Box sx={{ display: { xl: 'inherit', xs: 'none' } }}>{this.renderLeftContentContainer()}</Box>
//                     {this.renderRightContentContainer()}                  
//                 </div>
//                 {this.renderDrawer()}
//                 {this.renderSnackbar()}
//                 {this.renderServerStatus()}
//                 {this.renderFirstTimeModal()}
//             </div>
//         )
//     }


//     private renderFirstTimeModal(){
//         const storeState = AssistantDataStore.getState();
//         const close = ()=>storeState.setFirstTime(false);
//         return (
//             <Modal open={storeState.firstTime} onClose={close} disableEnforceFocus={true} disableAutoFocus={true}>
//                 <Box sx={styles.chatToolbar.modalSX}>
//                     <Typography id="modal-modal-title" variant="h5" component="h2">Welkom ondernemer!</Typography>
//                     <Typography id="modal-modal-subtitle" variant="body1" component="p" sx={{mt:2}}>
//                         Ontdek de veelzijdigheid van onze AI, Pietertje, in deze demo. <br/>Deze demo toont Pietertje's capaciteiten, maar geeft niet de volledige nauwkeurigheid van een geïmplementeerde assistent weer.<br/> Kleine foutjes kunnen voorkomen.
//                     <br/><br/>
//                     </Typography>     
//                     <Typography variant="body1" component="p" sx={{mt:2, display:{ xs:"inherit", md:"none" }}}>Deze demo is het best uit te voeren op een laptop of PC<br/><br/></Typography>
//                     <Button sx={{
//                         borderRadius: 12,
//                         backgroundColor: "rgba(88, 196, 220,0.2)",
//                         textTransform: "none",
//                         pl:2,pr:2,
//                         color: accentColor
//                     }} color="inherit" onClick={()=>close()}>Ik begrijp het</Button>
//                 </Box>
//             </Modal>
//         )
//     }

//     private renderLeftContentContainer(){ //TODO clean up
//         const storeState = AssistantDataStore.getState();
//         return (
//             <div style={styles.mainPage.leftContainer.container}>
//                 <SettingsToolbar>
//                     <SuggestionMenu/>
//                 </SettingsToolbar>
//                 <div style={styles.mainPage.leftContainer.contentWrapper}>
//                     <h3>Hoe ziet jouw ideale assistent eruit?</h3>
//                     {/* <Typography variant="h5" component="h4" sx={{ flexGrow: 1 }} >Hoe ziet jouw ideale assistent eruit?</Typography> */}
//                     <p>Beschrijf het bedrijf</p>
//                     {this.renderSettingsTextField(storeState.companyDescriptionSetting,val=>storeState.setCompanyDescriptionSetting(val))}
//                     <p>Beschrijf de functie</p>
//                     {this.renderSettingsTextField(storeState.descriptionSetting,val=>storeState.setDescriptionSetting(val))}
//                     <p>Beschrijf de persoonlijkheid</p>
//                     {this.renderSettingsTextField(storeState.personalitySetting,val=>storeState.setPersonalitySetting(val))}
//                     <div style={styles.mainPage.leftContainer.switchContainer}>
//                             <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} >Stricte modus</Typography>
//                             <Switch  checked={storeState.strictMode} onChange={(e)=>{storeState.setStrictMode(e.target.checked)}} />
//                             <HelpMenu title={"Stricte Modus"}><p>Een optie die je aan geeft om de assistent streng binnen zijn kaders te houden.</p></HelpMenu>
//                         </div>
//                     <ApplyButton applySettings={()=>this.applySettings()}/>   
//                 </div>
//             </div>
//         )
//     }
    
//     private renderSettingsTextField(val: string, onChange:(val: string)=>void){ //TODO move to component
//         return (
//             <TextField 
//                 style={styles.mainPage.leftContainer.textField} 
//                 multiline={true} rows={4} 
//                 value={val} InputProps={{
//                     disableUnderline: true,
//                     style:styles.bottomBar.chatBox //TODO
//                 }}
//                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//                     onChange(event.target.value);
//                 }}
//             />  
//         )
//     }

//     private renderRightContentContainer(){ //TODO clean up
//         return (
//             <div style={styles.mainPage.rightContainer.container}>
//                 <AppToolbar
//                      onMenuClick={()=>this.setState({drawerOpen:true})}
//                      title={"Allround AI Assistent Demo"}
//                      mobileTitle={"AI Assistent Demo"}
//                 /> 
//                 {this.renderChatContainer()}
//                 {/* {this.renderBottomBar()} */}
//                 <ChatInput 
//                     value={this.state.input}
//                     onChange={(val:string)=>this.setState({input:val})}
//                     placeholder={Constants.INPUT_PLACEHOLDER}
//                     onSubmit={()=>this.handleButtonInput()}
//                     canType={this.state.isTyping}
//                 />
//                 {/* <DisclaimerText/> */}<Box sx={{height:{ xs:24, md:36}}}/>
//             </div>
//         )
//     }

//     //     //TODO check if input is changed and notify
//     // //TODO move to component
//     // private renderBottomBar() { 
//     //     return (
//     //         <Box sx={styles.bottomBar.inputContainer}>
//     //             <Box sx={styles.bottomBar.inputBar}>
//     //                 <TextField
//     //                     variant="standard"
//     //                     multiline={true}
//     //                     style={{ flexGrow: 1 }}
//     //                     InputProps={{
//     //                         disableUnderline: true,
//     //                         style: styles.bottomBar.chatBox,
//     //                         onKeyDown: event => this.handleInput(event)
//     //                     }}
//     //                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//     //                         this.setState({ input: event.target.value })
//     //                     }}
//     //                     value={this.state.input}
//     //                     placeholder={Constants.INPUT_PLACEHOLDER} />
//     //             </Box>   
//     //             <IconButton
//     //                 disableRipple={true}
//     //                 style={styles.bottomBar.sendButton}
//     //                 size="large"
//     //                 onClick={() => this.handleButtonInput()}
//     //                 color="inherit"
//     //                 aria-label="menu">
//     //                 <SendIcon />
//     //             </IconButton>   
//     //         </Box>
//     //     )
//     // }

//     private renderDrawer(){ 
//         AssistantDataStore.getInitialState();
//         return (
//             <Drawer
//                 anchor="left"                
//                 open={this.state.drawerOpen}
//                 onClose={()=>{
//                     //this.setState({drawerOpen:false});
//                     //this.applySettings(); //TODO?
//                 }}
//                 variant="temporary"
//                 sx={styles.mainPage.drawer}>
//                 {this.renderLeftContentContainer()}
//             </Drawer>
//         )
//     }

//     private renderSnackbar(){
//         return (
//             <Snackbar
//                 open={this.state.showSnackbar}
//                 onClose={()=>this.setState({showSnackbar:false})}
//                 message="Veranderingen zijn opgeslagen."
//                 ContentProps={styles.mainPage.snackbar}
//                 autoHideDuration={1200}
//                 />
//         )
//     }



//     private renderChatContainer(){ 
//         this.attachScrollListener();
//         return(
//             <MainContainer style={styles.mainPage.rightContainer.chatContainer}>
//                 <MessageList
//                     ref={this.messageListRef}
//                     autoScrollToBottom={this.state.scrollToBottom}
//                     typingIndicator={this.state.isTyping? <TypingIndicator content={AI_NAME+" is aan het typen"} /> : null}>
//                     {this.state.messages.map((m: any,i) => {
//                         if(+m.sender === Constants.CHATTER.SYSTEM) return null;
//                         if(m.message === "") return null;
//                         return <Message key={i} model={m}/>
//                     })}
//                 </MessageList>
//                     {this.scrollToBottomButton()}
//             </MainContainer>
//         )
//     }

//     private scrollToBottomButton(){
//         const dimension = { xs: 36, md: 48}
//         return (
//             <IconButton onClick={()=>this.scrollToBottom()} sx={{
//                 position: "absolute",
//                 //display: this.state.scrollToBottomButton? "inherit" : "none",
//                 left: "50%",
//                 height: this.state.scrollToBottomButton? dimension : 0,
//                 width: this.state.scrollToBottomButton? dimension : 0,
//                 visibility: this.state.scrollToBottomButton? "inherit" : "hidden",
//                 opacity: this.state.scrollToBottomButton? 1 : 0,
//                 transition: "all 0.2s ease-in-out",
//                 //right: 0,
//                 bottom: 0,
//                 borderRadius: "2em",
//                 transform: "translate(-50%, -50%)",
//                 backgroundColor: "rgba(35,39,47,1)",
//                 color: "white",
//                 "&:hover": {
//                     backgroundColor: "rgba(255,255,255,0.3)",
//                     color: "white",
//                 }
//             }}>
//                 <ArrowDownwardIcon/>
//             </IconButton>
//         );
//     }

//     private messageListRef = React.createRef<HTMLDivElement>();
//     private scrollToBottom()  {    
//         //@ts-ignore
//         this.messageListRef.current?.scrollToBottom( 'auto' );
//     }

//     private attachScrollListener(){// TODO remove listener
//         const classTargets = document?.getElementsByClassName('scrollbar-container');
//         if(classTargets.length<1) return;

//         const list = classTargets[0];
//         list.addEventListener('scroll', event=>{
//             const isScrollAtBottom = (Math.ceil(list.scrollTop) + list.clientHeight >= list.scrollHeight) 
//             this.setState({scrollToBottomButton: !isScrollAtBottom});
//         });
//     }


//     private renderServerStatus(){
//         return ( 
//             <Box sx={{...styles.serverStatus.container, display: AssistantDataStore.getState().serverRunning? "none" : "inherit"}}>
//                 <Typography variant="h6" color="text.secondary" sx={styles.serverStatus.text}>De server is momenteel niet bereikbaar. Probeer het later opnieuw.</Typography>
//             </Box>
//         );
//     }

//     private handleButtonInput(){
//         if(this.state.input.trim().length<1 || this.state.isTyping) return;
//         this.sendMessage();
//     }

//     private handleInput(event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>){
//         const isEnter = event.key === 'Enter';
//         if(this.state.isTyping) {
//             event.preventDefault();
//             return;
//         } 
//         if(isEnter && this.state.input.trim().length<1){ 
//             event.preventDefault();
//             return;
//         };
//         if(event.shiftKey) return;
//         if(isEnter) {
//             event.preventDefault();
//             this.sendMessage();   
//         }
//     }

//     private buildUIMessageModel(message:string, id:number): MessageModel{
//         const outgoing = id === Constants.CHATTER.USER;
//         return {
//             position:"normal",
//             direction: outgoing? "outgoing" : "incoming",
//             message: message,
//             sentTime: "just now",
//             sender: outgoing? "User" : AI_NAME
//         }
//     }

//     private addMessageToUI(message:string, id:number){
//         this.state.messages.push(this.buildUIMessageModel(message,id));
//         this.setState({input: "", isTyping: id === Constants.CHATTER.USER}); //TODO
//     }

//     private async sendMessage(){
//         const messageContent = this.state.input;

//         this.addMessageToUI(messageContent, Constants.CHATTER.USER) //TODO move to onMessageUpdate
//         this.setState({input: "", isTyping: true});
//         await this.checkServerStatus();
//         if(!AssistantDataStore.getState().serverRunning) return;
//         await MessageServiceInstance.queryAI(messageContent);
//     }
    
//     public onMessagesUpdate(messages: Array<MessageModel>) { //TODO update based on store? 
//         const chat = [];
//         chat.push(this.buildDefault()) //TODO wacht... is dit handig?
//         messages.forEach(message=>chat.push(message));

//         console.log(messages);
//         console.log(chat);

//         this.setState({isTyping:false, messages:chat});
//     }

//     private applySettings(){ 
//         this.setState({showSnackbar:true, drawerOpen:false});
//         this.setup();
//         this.clear(); //TODO moet dit in the onMessageUpdate? Moet dit in de store?
//     }

//     private clear(){
//         this.setState({messages: [this.buildDefault()], scrollToBottom: false});
//         setTimeout(()=>{this.setState({scrollToBottom: true})},100);
//     }

//     private buildDefault(): MessageModel{
//         return this.buildUIMessageModel(AI_PLACEHOLDER, Constants.CHATTER.AI);
//     }

//     public componentDidMount(): void {
//             MessageServiceInstance.addListener(this);
//             this.setup();
//             this.addMessageToUI(AI_PLACEHOLDER, Constants.CHATTER.AI);
//             setTimeout(()=>{this.setState({scrollToBottom: true})},100);
//         this.checkServerStatus();  //TODO global?
//     }

//     private async checkServerStatus(){ //TODO move to a service // DISABLE chat!
//         const storeState = AssistantDataStore.getState();
//         const serverDown = ()=>{
//             storeState.setServerRunning(false);
//             this.setState({isTyping:false})
//         }
//         try{
//             const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/service`);     
//             if (response.ok) {
//                 console.log(`Server is bereikbaar, status: ${response.status}`);
//                 storeState.setServerRunning(true);
//             } else {
//                 console.log(`Server is niet bereikbaar, status: ${response.status}`);
//                 serverDown();
//             }
//         } catch(e){
//             console.log(e);
//             console.log(`Server is niet bereikbaar, status: ${e}`);
//             serverDown();
//         }   
//     }

//     private async ping(){ //TODO move to a service // DISABLE chat!
//         await this.checkServerStatus();
//         setInterval(async ()=>{
//             await await this.checkServerStatus();
//         },1 * 60 * 1000);
//     }

//     private setup(){
//         const state = AssistantDataStore.getState();
//         // MessageServiceInstance.setupAssistant(
//         //     state.descriptionSetting,
//         //     state.personalitySetting,
//         //     state.companyDescriptionSetting,
//         //     state.strictMode
//         // );
//     }
// }




class MainAssistant extends Component {

    private deepCopyOfStore = this.json({...AssistantDataStore.getState()});
    public state = {
        systemMessages : this.buildSystemMessages(),
    }
    
    public render(){
        const storeState = AssistantDataStore.getState();
        const temp = storeState.strictMode? (storeState.temperature) : Constants.DEFAULT_AI_SETTINGS.temperature;
        return (
            <BaseChat
                store={AssistantDataStore}
                chatTitle={"AI Assistent Demo"}
                chatTitleMobile={"AI Assistent Demo"}
                defaultMessageContent={DefaultMessageDOM(AI_NAME)}
                actionContainer={this.renderLeftContentContainer()}
                temperature={temp}
                systemMessages={this.state.systemMessages}
                model={storeState.model}
            />
        );
    }

    private renderLeftContentContainer(){ //TODO clean up
        const storeState = AssistantDataStore.getState();
        return (
            <div style={styles.mainPage.leftContainer.container}>
                <SettingsToolbar>
                    <SuggestionMenu/>
                </SettingsToolbar>
                <div style={styles.mainPage.leftContainer.contentWrapper}>
                    <h3>Hoe ziet jouw ideale assistent eruit?</h3>
                    <p>Beschrijf het bedrijf</p>
                    {this.renderSettingsTextField(storeState.companyDescriptionSetting,val=>storeState.setCompanyDescriptionSetting(val))}
                    <p>Beschrijf de functie</p>
                    {this.renderSettingsTextField(storeState.descriptionSetting,val=>storeState.setDescriptionSetting(val))}
                    <p>Beschrijf de persoonlijkheid</p>
                    {this.renderSettingsTextField(storeState.personalitySetting,val=>storeState.setPersonalitySetting(val))}
                    <div style={styles.mainPage.leftContainer.switchContainer}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} >Stricte modus</Typography>
                        <Switch  checked={storeState.strictMode} onChange={(e)=>{storeState.setStrictMode(e.target.checked)}} />
                        <HelpMenu title={"Stricte Modus"}><p>Een optie die je aan geeft om de assistent streng binnen zijn kaders te houden.</p></HelpMenu>
                    </div>
                    {storeState.strictMode? this.renderTemperatureSlider() : <></>}
                    <SettingsNotice/>
                </div>
            </div>
        )
    }

    private renderTemperatureSlider(){
        const storeState = AssistantDataStore.getState();
        return (
            <Box sx={{backgroundColor: extraDarkColor, borderRadius:4, display:"flex", flexDirection:"row", alignItems:"center", p:2}}>
                <Typography variant="body2" sx={{mr:2}} >Strictheid</Typography>
                <Box sx={{flexGrow:1}}>
                    <Slider
                        sx={{width:"100%"}}
                        aria-label="temp"
                        value={1-storeState.temperature}
                        valueLabelDisplay="auto"
                        step={0.1}
                        onChange={(event, newValue) => {
                            storeState.setTemperature(1-(newValue as number));
                        }}
                        min={0}
                        max={1}
                    />
                </Box>
            </Box>
        )
    }
    
    private renderSettingsTextField(val: string, onChange:(val: string)=>void){ //TODO move to component
        return (
            <TextField 
                style={styles.mainPage.leftContainer.textField} 
                multiline={true} rows={4} 
                value={val} InputProps={{
                    disableUnderline: true,
                    style:styles.bottomBar.chatBox //TODO
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
            />  
        )
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        const storeState = this.json(AssistantDataStore.getState());    
        if(storeState !== this.deepCopyOfStore){ 
            this.deepCopyOfStore = storeState;      
            this.setState({systemMessages: this.buildSystemMessages()});
        } 
    }

    private buildSystemMessages(){
        const {strictMode, companyDescriptionSetting, descriptionSetting, personalitySetting} = AssistantDataStore.getState();
        const messages = [];

        if(strictMode) messages.push(Constants.INSTRUCTIONS_MIXIN);
        
        messages.push(`Je werkt voor het volgende bedrijf: ${companyDescriptionSetting}. Dit is je beschrijving en functie: ${descriptionSetting}. Dit is je persoonlijkheid: ${personalitySetting}`);
        
        if(strictMode) messages.push(`Beantwoord geen vragen die niet gerelateerd zijn aan je functie, dit is erg belangrijk!`);
        
        return messages;
    }

    private json(object: any){ //move to utils
        return JSON.stringify(object);
    }
}

export const Main = storeSubscription(MainAssistant,AssistantDataStore); 
