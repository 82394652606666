import { Component } from "react";
import { AssistantDataStore } from "../../data/data-store";
import { BaseChat } from "./base-chat";

export class VisionMain extends Component {

    render(){
        return (
            <BaseChat
                store={AssistantDataStore}
                logo={true}
                singlePage={true}
                mobileMenu={false}
                attachmentEnabled={true}
                chatTitle={"AI Vision Demo"}
                chatTitleMobile={"AI Vision Demo"}
                defaultMessageContent={this.defaultMessageContent()}
                actionContainer={<></>}
                temperature={0}
                systemMessages={this.buildSystemMessages()}
            />
        );
    }

    private defaultMessageContent(){
        return "Hoi, ik ben een AI vision assistent. In deze demo kan je mij voorzien van een afbeelding en dan zal ik zo duidelijk mogelijk vertellen wat ik zie. Vraag me vooral om details.";
    }

    private buildSystemMessages(){
        return [`Je bent een AI vision assistent.
        Je taak is om zo goed mogelijk te vertellen wat je ziet op een afbeelding. 
        Als er geen specifieke vraag is vanuit de gebruiker vertel dan wat je ziet op de afbeelding. 
        Ga verder niet in op vragen die niet te maken hebben met het analyseren van een foto.`.trim()];
    }
}