import React from 'react';
import ReactDOMServer from 'react-dom/server';

export function DefaultMessage({text, file }: { text: string, file: string }) {
  return (
        <>
            <img src={file} alt={text} style={{height:200, width:200, paddingLeft:16, objectFit:"contain"}} />
            <p>{text}</p>
        </>
  );
}

export const ImageMessageDOM = (text: string, file:string)=> ReactDOMServer.renderToString(<DefaultMessage file={file} text={text}/>);
